import {
  createConfigurationRequest,
  createConfigurationSuccess,
  createConfigurationFailure,
  fetchOrderDataRequest,
  fetchOrderDataSuccess,
  fetchOrderDataFailure,
} from '../redux/actions/summaryActions';

import history from '../shared/helpers/history';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

// create configuration with selected parameters
export function createConfigurationAction(params) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(params),
  };

  return (dispatch) => {
    dispatch(createConfigurationRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/pantheon${process.env.REACT_APP_USER_TOKEN}`, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(createConfigurationSuccess(res.Pantheon_ID));
        history.push(`/summary/${res.Pantheon_ID}`);
      })
      .catch((error) => dispatch(createConfigurationFailure(error)));
  };
}

// load previously saved configuration and redirect to summary on success
export function loadSavedConfiguration(id) {
  const requestOptions = { method: 'GET' };

  return (dispatch) => {
    dispatch(fetchOrderDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/orders/summary/${id}${process.env.REACT_APP_USER_TOKEN}`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchOrderDataSuccess(res));
        history.push(`/summary/${id}`);
      })
      .catch((error) => dispatch(fetchOrderDataFailure(error)));
  };
}
