import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Configurator from '../../Configurator';

export default () => (
  <Switch>
    <Route exact path="/model" component={Configurator} />
    <Route path="/model/:slug" component={Configurator} />
  </Switch>
);
