import {
  SET_CONFIG_PAGE_HEADER,
  SET_CONFIG_PAGE,
  SET_MODEL_COLOR,
  SET_MODEL_QUANTITY,
  ADD_MODEL_MECHANICAL_ACCESSORIES,
  REMOVE_MODEL_MECHANICAL_ACCESSORIES,
  ADD_MODEL_MECHANICAL_EQUIPMENT,
  REMOVE_MODEL_MECHANICAL_EQUIPMENT,
  SET_MODEL_ID,
  ADD_ITEM_TO_REQUIRED_INTERNET_LIST,
  REMOVE_ITEM_FROM_REQUIRED_INTERNET_LIST,
  SET_CONNECTION_TYPE_SELECTED,
} from '../actions/configuratorActions';

const initialState = {
  configPage: 'colors', // colors, accessories, equipment, connection, summary
  configPageHeader: 'look', // look, equipment, summary
  selectedModel: {
    product_id: null,
    ral_color_id: 200,
    module_ids: [],
    accessory_ids: [],
    quantity: 1,
  },
  modulesThatRequireInternet: [],
  connectionTypeSelected: {},
};

function configuratorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIG_PAGE:
      return {
        ...state,
        configPage: action.payload,
      };
    case SET_CONFIG_PAGE_HEADER:
      return {
        ...state,
        configPageHeader: action.payload,
      };
    case SET_MODEL_COLOR:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          ral_color_id: action.payload,
        },
      };
    case SET_MODEL_QUANTITY:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          quantity: action.payload,
        },
      };
    case ADD_MODEL_MECHANICAL_ACCESSORIES:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          accessory_ids: [...state.selectedModel.accessory_ids, action.payload],
        },
      };
    case REMOVE_MODEL_MECHANICAL_ACCESSORIES:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          accessory_ids: state.selectedModel.accessory_ids.filter((accessoryID) => accessoryID !== action.payload),
        },
      };
    case ADD_MODEL_MECHANICAL_EQUIPMENT:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          module_ids: [...state.selectedModel.module_ids, action.payload],
        },
      };
    case REMOVE_MODEL_MECHANICAL_EQUIPMENT:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          module_ids: state.selectedModel.module_ids.filter((equipmentID) => equipmentID !== action.payload),
        },
      };
    case SET_MODEL_ID:
      return {
        ...state,
        selectedModel: {
          ...state.selectedModel,
          product_id: action.payload,
        },
      };
    case ADD_ITEM_TO_REQUIRED_INTERNET_LIST:
      return {
        ...state,
        modulesThatRequireInternet: [...state.modulesThatRequireInternet, action.payload],
      };
    case REMOVE_ITEM_FROM_REQUIRED_INTERNET_LIST:
      return {
        ...state,
        modulesThatRequireInternet: state.modulesThatRequireInternet.filter((id) => id !== action.payload),
      };
    case SET_CONNECTION_TYPE_SELECTED:
      return {
        ...state,
        connectionTypeSelected: action.payload,
      };
    default:
      return state;
  }
}

export default configuratorReducer;
