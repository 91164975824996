// PRICES - code commented for NOW
import React from 'react';
import PropTypes from 'prop-types';
import EquipmentInfo from './EquipmentInfo';
import CloseIcon from '../../Icon/closeIcon';

const AccessoriesInfo = ({
  onRemoveAccessoryClick,
  onRemoveEquipmentClick,
  data,
  // basicPrice,
  accessoryOptionIsRemovable,
  equipmentOptionIsRemovable,
  modelInfo,
  currentPage,
}) => (
  <div className="product-info">
    {/* <div className="info-item">
      <span>Basic model price</span><span className="price right">{basicPrice} €</span>
    </div> */}
    <div className="info-item">
      <span>Color: {data.color.name}</span>
      {/* <span className="price right">{data.color.price_formatted} €</span> */}
    </div>
    <div className="selected-accessories-list">
      <ul className="info-list-item">
        {data.accessories.map((accessory) => (
          <li key={accessory.id}>
            {accessoryOptionIsRemovable
            && (
              <button className="close-btn" type="button" onClick={() => onRemoveAccessoryClick(accessory)}>
                <CloseIcon />
              </button>
            )}
            <span>{accessory.name}</span>
            {/* <span className="price right">{accessory.price_formatted} €</span> */}
          </li>
        ))}
      </ul>
    </div>
    <EquipmentInfo
      onRemoveClick={onRemoveEquipmentClick}
      isClickable={equipmentOptionIsRemovable}
      data={data}
      modelInfo={modelInfo}
      currentPage={currentPage}
    />
  </div>
);

AccessoriesInfo.propTypes = {
  onRemoveEquipmentClick: PropTypes.func,
  onRemoveAccessoryClick: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  // basicPrice: PropTypes.string.isRequired,
  accessoryOptionIsRemovable: PropTypes.bool,
  equipmentOptionIsRemovable: PropTypes.bool,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  currentPage: PropTypes.string,
};

AccessoriesInfo.defaultProps = {
  onRemoveEquipmentClick: () => {},
  onRemoveAccessoryClick: () => {},
  accessoryOptionIsRemovable: false,
  equipmentOptionIsRemovable: false,
  currentPage: '',
};

export default AccessoriesInfo;
