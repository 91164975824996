import React from 'react';

const CloseModalIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="16" stroke="black" strokeWidth="1.72973" />
    <path
      d="M11.3789 11.3789L17.0005 17.0005M17.0005 17.0005L22.6221 22.6221M17.0005 17.0005L22.6221
    11.3789M17.0005 17.0005L11.3789 22.6221"
      stroke="black"
      strokeWidth="1.72973"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CloseModalIcon;
