export const SET_OVERVIEW_COLOR = 'SET_OVERVIEW_COLOR';
export const SET_OVERVIEW_QUANTITY = 'SET_OVERVIEW_QUANTITY';
export const SET_OVERVIEW_BASIC_PRICE = 'SET_OVERVIEW_BASIC_PRICE';
export const ADD_OVERVIEW_MECHANICAL_ACCESSORY = 'ADD_OVERVIEW_MECHANICAL_ACCESSORY';
export const REMOVE_OVERVIEW_MECHANICAL_ACCESSORY = 'REMOVE_OVERVIEW_MECHANICAL_ACCESSORY';
export const ADD_OVERVIEW_MECHANICAL_EQUIPMENT = 'ADD_OVERVIEW_MECHANICAL_EQUIPMENT';
export const REMOVE_OVERVIEW_MECHANICAL_EQUIPMENT = 'REMOVE_OVERVIEW_MECHANICAL_EQUIPMENT';
export const SET_PRESELECTED_MODULE = 'SET_PRESELECTED_MODULE';

export function setOverviewColor(color) {
  return {
    type: SET_OVERVIEW_COLOR,
    payload: color,
  };
}

export function setOverviewQuantity(number) {
  return {
    type: SET_OVERVIEW_QUANTITY,
    payload: number,
  };
}

export function setOverviewBasicPrice(price) {
  return {
    type: SET_OVERVIEW_BASIC_PRICE,
    payload: price,
  };
}

export function addOverviewMechanicalAccesssories(accessory) {
  return {
    type: ADD_OVERVIEW_MECHANICAL_ACCESSORY,
    payload: accessory,
  };
}

export function removeOverviewMechanicalAccesssories(accessoryID) {
  return {
    type: REMOVE_OVERVIEW_MECHANICAL_ACCESSORY,
    payload: accessoryID,
  };
}

export function addOverviewMechanicalEquipment(equipment) {
  return {
    type: ADD_OVERVIEW_MECHANICAL_EQUIPMENT,
    payload: equipment,
  };
}

export function removeOverviewMechanicalEquipment(equipmentID) {
  return {
    type: REMOVE_OVERVIEW_MECHANICAL_EQUIPMENT,
    payload: equipmentID,
  };
}

export function setPreselectedModule(preselectedModule) {
  return {
    type: SET_PRESELECTED_MODULE,
    payload: preselectedModule,
  };
}
