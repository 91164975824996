import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../components/Icon/logoIcon';

const Loading = ({ loading }) => (
  <div className={`load${loading ? '' : ' loaded'}`}>
    <div className="load__icon-wrap">
      <Logo />
    </div>
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;
