// Fetch list of all models for selected product
import {
  fetchModelsRequest,
  fetchModelsSuccess,
  fetchModelsFailure,
  fetchModelInfoRequest,
  fetchModelInfoSuccess,
  fetchModelInfoFailure,
} from '../redux/actions/modelsActions';
import { setModelID, setModelColor, addModelMechanicalAccesssories } from '../redux/actions/configuratorActions';
import {
  setOverviewBasicPrice, setOverviewColor, addOverviewMechanicalAccesssories,
} from '../redux/actions/overviewActions';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

const requestOptions = { method: 'GET' };

export function fetchModels(product) {
  return (dispatch) => {
    dispatch(fetchModelsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/type/${product}${process.env.REACT_APP_USER_TOKEN}`,
      requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchModelsSuccess(res));
      })
      .catch((error) => dispatch(fetchModelsFailure(error)));
  };
}

// Fetch all data for selected model
export function fetchModelInfo(model) {
  return (dispatch) => {
    dispatch(fetchModelInfoRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/${model}${process.env.REACT_APP_USER_TOKEN}`,
      requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchModelInfoSuccess(res));
        dispatch(setOverviewBasicPrice(res.price));
        dispatch(setOverviewColor(res.default_color));
        dispatch(setModelColor(res.default_color.id));
        dispatch(setModelID(res.id));

        // TERRA PUBLIC CAN default lid set to 12 - mixed waste lid
        const mixedLidAccessory = res.accessories.find((item) => item.id === 12);
        if (model === 'terra-public-can') {
          dispatch(addModelMechanicalAccesssories(12));
          dispatch(addOverviewMechanicalAccesssories(mixedLidAccessory));
        }
      })
      .catch((error) => dispatch(fetchModelInfoFailure(error)));
  };
}
