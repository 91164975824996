import React from 'react';

const LogoIcon = () => (

  <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M17.7987 3.82258C16.7423 3.82258 15.8875 2.96774
    15.8875 1.91129C15.8875 0.854839 16.7423 0 17.7987 0C18.8552
    0 19.71 0.854839 19.71 1.91129C19.71 2.96774 18.8552 3.82258
    17.7987 3.82258Z"
      fill="black"
    />
    <path
      d="M17.7987 9.12141C16.7423 9.12141 15.8875 8.26657
    15.8875 7.21012C15.8875 6.15367 16.7423 5.29883 17.7987
    5.29883C18.8552 5.29883 19.71 6.15367 19.71 7.21012C19.71
     8.25851 18.8552 9.12141 17.7987 9.12141Z"
      fill="black"
    />
    <path
      d="M17.7987 14.4105C16.7423 14.4105 15.8875 13.5556
    15.8875 12.4992C15.8875 11.4427 16.7423 10.5879 17.7987
    10.5879C18.8552 10.5879 19.71 11.4427 19.71 12.4992C19.71
     13.5556 18.8552 14.4105 17.7987 14.4105Z"
      fill="black"
    />
    <path
      d="M17.7987 19.7093C16.7423 19.7093 15.8875 18.8545
    15.8875 17.798C15.8875 16.7416 16.7423 15.8867 17.7987
    15.8867C18.8552 15.8867 19.71 16.7416 19.71 17.798C19.71
    18.8464 18.8552 19.7093 17.7987 19.7093Z"
      fill="black"
    />
    <path
      d="M17.7987 25.0003C16.7423 25.0003 15.8875 24.1455
    15.8875 23.089C15.8875 22.0326 16.7423 21.1777 17.7987
    21.1777C18.8552 21.1777 19.71 22.0326 19.71 23.089C19.71
    24.1455 18.8552 25.0003 17.7987 25.0003Z"
      fill="black"
    />
    <path
      d="M7.2092 3.82258C6.15275 3.82258 5.29791 2.96774 5.29791
    1.91129C5.28984 0.854839 6.14468 0 7.20114 0C8.25759 0 9.11243
    0.854839 9.11243 1.91129C9.12049 2.96774 8.26565 3.82258
    7.2092 3.82258Z"
      fill="black"
    />
    <path
      d="M7.20988 9.12141C6.15342 9.12141 5.29858 8.26657
    5.29858 7.21012C5.29858 6.15367 6.15342 5.29883 7.20988 5.29883C8.26633
    5.29883 9.12117 6.15367 9.12117 7.21012C9.12117 8.25851 8.26633
    9.12141 7.20988 9.12141Z"
      fill="black"
    />
    <path
      d="M7.20988 14.4105C6.15342 14.4105 5.29858 13.5556 5.29858 12.4992C5.29858
    11.4427 6.15342 10.5879 7.20988 10.5879C8.26633 10.5879 9.12117 11.4427 9.12117
    12.4992C9.12117 13.5556 8.26633 14.4105 7.20988 14.4105Z"
      fill="black"
    />
    <path
      d="M7.20988 19.7093C6.15342 19.7093 5.29858 18.8545 5.29858 17.798C5.29858
    16.7416 6.15342 15.8867 7.20988 15.8867C8.26633 15.8867 9.12117 16.7416 9.12117
    17.798C9.12117 18.8464 8.26633 19.7093 7.20988 19.7093Z"
      fill="black"
    />
    <path
      d="M7.20988 25.0003C6.15342 25.0003 5.29858 24.1455 5.29858
    23.089C5.29858 22.0326 6.15342 21.1777 7.20988 21.1777C8.26633 21.1777
    9.12117 22.0326 9.12117 23.089C9.12117 24.1455 8.26633 25.0003 7.20988
    25.0003Z"
      fill="black"
    />
    <path
      d="M12.5002 9.12141C11.4437 9.12141 10.5889 8.26657 10.5889
    7.21012C10.5889 6.15367 11.4437 5.29883 12.5002 5.29883C13.5566
    5.29883 14.4115 6.15367 14.4115 7.21012C14.4115 8.25851 13.5566
    9.12141 12.5002 9.12141Z"
      fill="black"
    />
    <path
      d="M23.088 9.12141C22.0316 9.12141 21.1768 8.26657 21.1768
     7.21012C21.1768 6.15367 22.0316 5.29883 23.088 5.29883C24.1445
     5.29883 24.9993 6.15367 24.9993 7.21012C24.9993 8.25851 24.1445
      9.12141 23.088 9.12141Z"
      fill="black"
    />
    <path
      d="M1.91129 9.12141C0.854839 9.12141 0 8.25851 0 7.21012C0 6.15367
     0.854839 5.29883 1.91129 5.29883C2.96774 5.29883 3.82258 6.15367 3.82258
     7.21012C3.82258 8.25851 2.96774 9.12141 1.91129 9.12141Z"
      fill="black"
    />
    <path
      d="M23.088 19.7093C22.0316 19.7093 21.1768 18.8545 21.1768
    17.798C21.1768 16.7416 22.0316 15.8867 23.088 15.8867C24.1445 15.8867
    24.9993 16.7416 24.9993 17.798C24.9993 18.8464 24.1445 19.7093 23.088
    19.7093Z"
      fill="black"
    />
    <path
      d="M12.5002 19.7093C11.4437 19.7093 10.5889 18.8545 10.5889 17.798C10.5889
    16.7416 11.4437 15.8867 12.5002 15.8867C13.5566 15.8867 14.4115 16.7416 14.4115
    17.798C14.4115 18.8464 13.5566 19.7093 12.5002 19.7093Z"
      fill="black"
    />
    <path
      d="M1.91129 19.7096C0.854839 19.7096 0 18.8466 0 17.7902C0 16.7337 0.854839
     15.8789 1.91129 15.8789C2.96774 15.8789 3.82258 16.7337 3.82258 17.7902C3.82258
     18.8466 2.96774 19.7096 1.91129 19.7096Z"
      fill="black"
    />
  </svg>
);
export default LogoIcon;
