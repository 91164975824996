import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseModalIcon from '../../Icon/closeModalIcon';
import CustomButton from '../../Button';

const RequiredModule = ({
  modalOpen, onCloseClick, requiredModuleNames,
}) => (
  <Modal
    isOpen={modalOpen}
    className="required-modal"
  >
    <div className="modal__header">
      <button className="close-modal-btn" type="button" onClick={() => onCloseClick()}> <CloseModalIcon /></button>
    </div>
    <div className="modal__content">
      <div className="modal__body">
        <p>
          Selected module <strong>{requiredModuleNames.name} </strong>
          requires this module/s to work:
        </p>
        <ul>
          {requiredModuleNames.requires.length > 0 && requiredModuleNames.requires.map((item) => (
            <li key={item}><strong>{item}</strong></li>
          ))}
        </ul>
        <br />
        <p>Automatically added to order list.</p>
      </div>
    </div>
    <div className="modal-ok-btn">
      <CustomButton
        text="ok"
        padding="15px 150px"
        onButtonClick={() => onCloseClick()}
      />
    </div>
  </Modal>
);

RequiredModule.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  requiredModuleNames: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.array,
  ])).isRequired,
};

export default RequiredModule;
