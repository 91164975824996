import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../Button';

const Equipment = ({
  equipmentList,
  onEquipmentSelect,
  selectedEquipment,
  modelInfo,
}) => {
  const requiredMultiModules = modelInfo.modules.filter((module) => (
    module.required_one_or_more.length > 0
  ));

  const requiredModulesIds = requiredMultiModules.map((item) => item.id);
  const allRequiredModulesSelected = requiredModulesIds.every(
    (requiredModulesId) => selectedEquipment.includes(requiredModulesId),
  );

  return (
    <div className="equipment-picker">
      <ul>
        {modelInfo.slug.includes('aerys') && requiredMultiModules.length > 0
        && requiredMultiModules.map((item) => (
        // display only optional modules and those with category_id = 2 (not internet connection modules)
        // also display only "visible" modules (e.g. user do not need to see Nvidia module, not clickable only required)
          !item.required && item.module_category_id === 2 && !!item.is_visible
            && (
            <li key={item.id}>
              <div className="modul-picture">
                <div className="main-image">
                  <img
                    src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}modules/${item.icon} `}
                    alt={item.icon}
                  />
                </div>
              </div>
              <div className="modul-info">
                <h2 className="modul-title">{item.name}</h2>
                <p className="modul-description">{item.description}</p>
              </div>
              <div className="modul-btn">
                {
                    !allRequiredModulesSelected
                      ? (
                        <CustomButton
                          text={selectedEquipment.includes(item.id) ? 'Added' : 'Add'}
                          icon="add"
                          padding="3px 15px"
                          customClass={`${selectedEquipment.includes(item.id) ? 'button-active' : ''}`}
                          onButtonClick={(event) => onEquipmentSelect(event, item)}
                          disabled={selectedEquipment.includes(item.id)}
                        />
                      )
                      : (
                        <CustomButton
                          text={selectedEquipment.includes(item.id) ? 'Remove' : 'Add'}
                          icon="add"
                          padding="3px 15px"
                          customClass={`${selectedEquipment.includes(item.id) ? 'button-active' : ''}`}
                          onButtonClick={(event) => onEquipmentSelect(event, item)}
                          disabled={selectedEquipment.includes(item.id) && !allRequiredModulesSelected}
                        />
                      )

                }
              </div>
            </li>
            )))}
        {equipmentList.map((item) => (
        // display only optional modules and those with category_id = 2 (not internet connection modules)
        // also display only "visible" modules (e.g. user do not need to see Nvidia module, not clickable only required)
          !item.required && item.module_category_id === 2 && !!item.is_visible && item.required_one_or_more.length === 0
        && (
        <li key={item.id}>
          <div className="modul-picture">
            <div className="main-image">
              <img
                src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}modules/${item.icon} `}
                alt={item.icon}
              />
            </div>
          </div>
          <div className="modul-info">
            <h2 className="modul-title">{item.name}</h2>
            <p className="modul-description">{item.description}</p>
          </div>
          <div className="modul-btn">
            <CustomButton
              text={selectedEquipment.includes(item.id) ? 'Remove' : 'Add'}
              icon="add"
              padding="3px 15px"
              customClass={`${selectedEquipment.includes(item.id) ? 'button-active' : ''}`}
              onButtonClick={(event) => onEquipmentSelect(event, item)}
            />
          </div>
        </li>
        )
        ))}
      </ul>
    </div>
  );
};

Equipment.propTypes = {
  equipmentList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEquipmentSelect: PropTypes.func.isRequired,
  selectedEquipment: PropTypes.arrayOf(PropTypes.number).isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
};

export default Equipment;
