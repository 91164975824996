import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../../Home';
import Configurator from './Configurator';
import Summary from '../../Summary';
import NotFound404 from '../../Default/404';

const MyRouter = () => (
  <main className="site-container">
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/model" component={Configurator} />
      <Route path="/summary/:configuration_id" component={Summary} />
      <Route component={NotFound404} />
    </Switch>
  </main>
);

export default MyRouter;
