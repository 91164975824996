import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LogoIcon from '../Icon/logoIcon';
import ArrowIcon from '../Icon/arrowRightIcon';

const HeaderConfigurator = ({ selected }) => (
  <div className="header-configurator">
    <div className="row">
      <Link to={{ pathname: 'https://www.include.eu/' }} target="_blank">
        <LogoIcon />
      </Link>
      <div className="header-configurator-wrapper">
        <h2 className={`look-label ${selected === 'look' && 'active'}`}>Choose your look</h2>
        <span className="arrow-icon">
          <ArrowIcon color={selected === 'look' ? '#000' : '#bdc4c9'} />
        </span>
        <h2 className={`equipment-label ${selected === 'equipment' && 'active'}`}>Equipment models</h2>
        <span className="arrow-icon">
          <ArrowIcon color={selected === 'equipment' ? '#000' : '#bdc4c9'} />
        </span>
        <h2 className={`summary-label ${selected === 'summary' && 'active'}`}>Summary</h2>
      </div>
    </div>
  </div>
);

HeaderConfigurator.propTypes = {
  selected: PropTypes.string.isRequired,
};

export default HeaderConfigurator;
