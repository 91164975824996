import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';

import {
  productsReducer,
  modelsReducer,
  configuratorReducer,
  overviewReducer,
  summaryReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  configurator: configuratorReducer,
  form: reduxFormReducer,
  models: modelsReducer,
  overview: overviewReducer,
  products: productsReducer,
  summary: summaryReducer,
});

const store = createStore(reducer, composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
  ),
));

export default store;
