import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Modal from '../Modal';

class TechnicalData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalTechOpen: false,
      modalEquipmentOpen: false,
    };

    this.openEquipmentModal = this.openEquipmentModal.bind(this);
    this.closeEquipmentModal = this.closeEquipmentModal.bind(this);
    this.openTechModal = this.openTechModal.bind(this);
    this.closeTechModal = this.closeTechModal.bind(this);
  }

  openEquipmentModal = () => {
    this.setState({ modalEquipmentOpen: true });
  };

  closeEquipmentModal = () => {
    this.setState({ modalEquipmentOpen: false });
  };

  openTechModal = () => {
    this.setState({ modalTechOpen: true });
  };

  closeTechModal = () => {
    this.setState({ modalTechOpen: false });
  };

  render() {
    const {
      modalTechOpen,
      modalEquipmentOpen,
    } = this.state;
    const { techSpecs, standardEquipment } = this.props;

    return (
      <>
        <div className="row info-model-summary">
          <div className="col-lg-8">
            {
            standardEquipment.modules && standardEquipment.modules.map((item) => (
              <img
                src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}technical-data-icons/${item.icon}`}
                alt={item.icon}
                key={item.id}
              />
            ))
          }
          </div>
        </div>
        <div className="row modal-links">
          <div className="col-lg-4 col-sm-6 p-0 tech-links">
            <button type="button" onClick={() => this.openTechModal()}>COMPLETE TECHNICAL DATA</button>
          </div>
          <div className="col-lg-4 col-sm-6 p-0 tech-links">
            <button type="button" onClick={() => this.openEquipmentModal()}>COMPLETE STANDARD EQUIPMENT</button>
          </div>
        </div>
        {techSpecs.length > 0 && (
          <Modal
            modalOpen={modalTechOpen}
            techSpecs={techSpecs}
            onCloseClick={() => this.closeTechModal()}
            modalType="technical"
          />
        )}
        {!isEmpty(standardEquipment) && (
          <Modal
            modalOpen={modalEquipmentOpen}
            standardEquipment={standardEquipment}
            onCloseClick={() => this.closeEquipmentModal()}
            modalType="equipment"
          />
        )}
      </>
    );
  }
}

TechnicalData.propTypes = {
  techSpecs: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  standardEquipment: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
};

export default TechnicalData;
