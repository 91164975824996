const validate = (values) => {
  const errors = {};
  if (!values.emailValue) {
    errors.emailValue = 'Email address field should not be empty.';
  }
  if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.emailValue)) {
    errors.emailValue = 'Invalid email address.';
  }

  return errors;
};

export default validate;
