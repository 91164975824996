export const SET_CONFIG_PAGE = 'SET_CONFIG_PAGE';
export const SET_CONFIG_PAGE_HEADER = 'SET_CONFIG_PAGE_HEADER';
export const SET_MODEL_COLOR = 'SET_MODEL_COLOR';
export const SET_MODEL_QUANTITY = 'SET_MODEL_QUANTITY';
export const ADD_MODEL_MECHANICAL_ACCESSORIES = 'ADD_MODEL_MECHANICAL_ACCESSORIES';
export const REMOVE_MODEL_MECHANICAL_ACCESSORIES = 'REMOVE_MODEL_MECHANICAL_ACCESSORIES';
export const ADD_MODEL_MECHANICAL_EQUIPMENT = 'ADD_MODEL_MECHANICAL_EQUIPMENT';
export const REMOVE_MODEL_MECHANICAL_EQUIPMENT = 'REMOVE_MODEL_MECHANICAL_EQUIPMENT';
export const SET_MODEL_ID = 'SET_MODEL_ID';
export const ADD_ID_TO_EXCLUDED_ACCESSORIES = 'ADD_ID_TO_EXCLUDED_ACCESSORIES';
export const REMOVE_ID_FROM_EXCLUDED_ACCESSORIES = 'REMOVE_ID_FROM_EXCLUDED_ACCESSORIES';
export const ADD_ID_TO_EXCLUDED_MODULES = 'ADD_ID_TO_EXCLUDED_MODULES';
export const REMOVE_ID_FROM_EXCLUDED_MODULES = 'REMOVE_ID_FROM_EXCLUDED_MODULES';
export const ADD_ITEM_TO_REQUIRED_INTERNET_LIST = 'ADD_ITEM_TO_REQUIRED_INTERNET_LIST';
export const REMOVE_ITEM_FROM_REQUIRED_INTERNET_LIST = 'REMOVE_ITEM_FROM_REQUIRED_INTERNET_LIST';
export const SET_CONNECTION_TYPE_SELECTED = 'SET_CONNECTION_TYPE_SELECTED';

export function setConfiguratorPage(page) {
  return {
    type: SET_CONFIG_PAGE,
    payload: page,
  };
}

export function setConfiguratorPageHeader(header) {
  return {
    type: SET_CONFIG_PAGE_HEADER,
    payload: header,
  };
}

export function setModelColor(color) {
  return {
    type: SET_MODEL_COLOR,
    payload: color,
  };
}

export function setModelQuantity(number) {
  return {
    type: SET_MODEL_QUANTITY,
    payload: number,
  };
}

export function addModelMechanicalAccesssories(accessoryID) {
  return {
    type: ADD_MODEL_MECHANICAL_ACCESSORIES,
    payload: accessoryID,
  };
}

export function removeModelMechanicalAccesssories(accessoryID) {
  return {
    type: REMOVE_MODEL_MECHANICAL_ACCESSORIES,
    payload: accessoryID,
  };
}

export function addModelMechanicalEquipment(equipmentID) {
  return {
    type: ADD_MODEL_MECHANICAL_EQUIPMENT,
    payload: equipmentID,
  };
}

export function removeModelMechanicalEquipment(equipmentID) {
  return {
    type: REMOVE_MODEL_MECHANICAL_EQUIPMENT,
    payload: equipmentID,
  };
}

export function setModelID(id) {
  return {
    type: SET_MODEL_ID,
    payload: id,
  };
}

export function addItemToRequiredInternetList(id) {
  return {
    type: ADD_ITEM_TO_REQUIRED_INTERNET_LIST,
    payload: id,
  };
}

export function removeItemFromRequiredInternetList(id) {
  return {
    type: REMOVE_ITEM_FROM_REQUIRED_INTERNET_LIST,
    payload: id,
  };
}

export function setConnectionTypeSelected(connectionModule) {
  return {
    type: SET_CONNECTION_TYPE_SELECTED,
    payload: connectionModule,
  };
}
