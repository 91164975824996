import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Header from '../../components/Header';
import SummaryMenu from '../../components/OverviewMenu/SummaryMenu';
import Gallery from '../../components/Gallery';
import TechnicalData from '../../components/TechnicalData';

import { setConfiguratorPage, setConfiguratorPageHeader } from '../../redux/actions/configuratorActions';
import { createConfigurationSuccess } from '../../redux/actions/summaryActions';
import { fetchModelDataByConfigId, fetchModelsTechData, fetchModelsStandardEquipment } from '../../api/summaryApi';

import history from '../../shared/helpers/history';

class Summary extends Component {
  componentDidMount() {
    const { match, dispatch } = this.props;
    const configId = match.params.configuration_id;

    history.listen((location, action) => {
      // for browser back botun click we should go to the homepage
      if (action === 'POP') history.push('/');
    });

    dispatch(setConfiguratorPage('summary'));
    dispatch(setConfiguratorPageHeader('summary'));
    dispatch(createConfigurationSuccess(configId));
    dispatch(fetchModelDataByConfigId(configId));
    dispatch(fetchModelsTechData(configId));
    dispatch(fetchModelsStandardEquipment(configId));
  }

  render() {
    const {
      summaryData,
      techSpecsData,
      standardEquipmentData,
      dispatch,
      modelInfo,
    } = this.props;

    return (
      <>
        <Header headerType="configurator" selected="summary" />
        {summaryData.deployed_products && (
        <Container className="summary-page">
          <SummaryMenu
            data={summaryData.deployed_products[0]}
            quantity={summaryData.deployed_products.length}
            configID={summaryData.configID}
            dispatch={dispatch}
            modelInfo={modelInfo}
          />
          <h1>{summaryData.deployed_products[0].name}</h1>
          <Gallery page="summaryPage" modelInfo={modelInfo} />
          <TechnicalData
            techSpecs={techSpecsData}
            standardEquipment={standardEquipmentData}
          />
        </Container>
        )}
      </>
    );
  }
}

Summary.propTypes = {
  summaryData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  techSpecsData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  standardEquipmentData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      configuration_id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
};

const mapStateToProps = (state) => ({
  summaryData: state.summary.selectedModelData,
  techSpecsData: state.summary.techSpecsData,
  standardEquipmentData: state.summary.standardEquipmentData,
  modelInfo: state.models.modelInfo,
});

export default connect(mapStateToProps)(Summary);
