import React, { Component } from 'react';
import { Modal, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import CloseModalIcon from '../../Icon/closeModalIcon';
import CustomButton from '../../Button';

import validate from './loadConfigurationValidator';

import { loadSavedConfiguration } from '../../../api/configuratorApi';

class LoadSavedConfiguration extends Component {
  constructor() {
    super();
    this.state = {
      configurationId: '',
      validationError: '',
    };
  }

  onInputChange = (event) => {
    this.setState({ validationError: '' });
    this.setState({
      configurationId: event.target.value,
    });
  }

  handleButtonClick = () => {
    const { dispatch } = this.props;
    const { configurationId } = this.state;
    const valuesToValidate = { configurationId };

    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      dispatch(loadSavedConfiguration(configurationId));
    } else {
      this.setState({ validationError: errors.configurationId });
    }
  }

  render() {
    const { modalOpen, onCloseClick, apiError } = this.props;
    const { configurationId, validationError } = this.state;

    return (
      <>
        <Modal
          isOpen={modalOpen}
        >
          <div className="modal__header">
            <button
              className="close-modal-btn"
              type="button"
              onClick={() => onCloseClick()}
            > <CloseModalIcon />
            </button>
            <h2 className="modal__title">Load your saved configuration</h2>
          </div>
          <div className="modal__body">
            <p className="email-text">Enter your configuration ID</p>
            { validationError
              && <span className="modal__input-error"> {validationError} </span>}
            <input
              name="configuration-id"
              type="text"
              className={`email-input ${configurationId && 'active'}`}
              placeholder="CONMMYY00001"
              value={configurationId}
              onChange={this.onInputChange}
              required
            />
            <Alert color="danger" isOpen={!!apiError}>
              {apiError}
            </Alert>
            <div className="help-link">
              <div className="link-text">
                <Link to={{ pathname: 'https://include.eu/get-in-touch/' }} target="_blank">
                  <span>NEED HELP?</span>
                </Link>
              </div>
              <div className="link-btn right">
                <CustomButton
                  disabled={!configurationId}
                  text="Continue"
                  onButtonClick={this.handleButtonClick}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

LoadSavedConfiguration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  apiError: PropTypes.PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  apiError: state.summary.error,
});

export default connect(mapStateToProps)(LoadSavedConfiguration);
