import {
  SET_OVERVIEW_COLOR,
  SET_OVERVIEW_QUANTITY,
  SET_OVERVIEW_BASIC_PRICE,
  ADD_OVERVIEW_MECHANICAL_ACCESSORY,
  REMOVE_OVERVIEW_MECHANICAL_ACCESSORY,
  ADD_OVERVIEW_MECHANICAL_EQUIPMENT,
  REMOVE_OVERVIEW_MECHANICAL_EQUIPMENT,
  SET_PRESELECTED_MODULE,
} from '../actions/overviewActions';

const initialState = {
  overviewData: {
    basic_model_price: 0,
    color: {},
    modules: [],
    accessories: [],
    quantity: 1,
    configuration_id: null,
  },
};

function overviewReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OVERVIEW_COLOR:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          color: action.payload,
        },
      };
    case SET_OVERVIEW_QUANTITY:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          quantity: action.payload,
        },
      };
    case SET_OVERVIEW_BASIC_PRICE:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          basic_model_price: action.payload,
        },
      };
    case ADD_OVERVIEW_MECHANICAL_ACCESSORY:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          accessories: [...state.overviewData.accessories, action.payload],
        },
      };
    case REMOVE_OVERVIEW_MECHANICAL_ACCESSORY:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          accessories: state.overviewData.accessories.filter((accessory) => accessory.id !== action.payload),
        },
      };
    case ADD_OVERVIEW_MECHANICAL_EQUIPMENT:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          modules: [...state.overviewData.modules, action.payload],
        },
      };
    case REMOVE_OVERVIEW_MECHANICAL_EQUIPMENT:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          modules: state.overviewData.modules.filter((equipment) => equipment.id !== action.payload),
        },
      };
    case SET_PRESELECTED_MODULE:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          modules: [...state.overviewData.modules, action.payload],
        },
      };
    default:
      return state;
  }
}

export default overviewReducer;
