import React from 'react';

const CloseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.59L5.41 4L4 5.41L6.59 8L4 10.59L5.41
    12L8 9.41L10.59 12L12 10.59L9.41 8L12 5.41L10.59 4L8 6.59ZM0 8C0 3.59 3.59 0 8 0C12.41 0
    16 3.59 16 8C16 12.41 12.41 16 8 16C3.59 16 0 12.41 0 8Z"
      fill="#242938"
    />
  </svg>
);

export default CloseIcon;
