// Fetch summary data using configuration ID number
import {
  fetchOrderDataRequest,
  fetchOrderDataSuccess,
  fetchOrderDataFailure,
  sendConfigurationByEmailRequest,
  sendConfigurationByEmailSuccess,
  sendConfigurationByEmailFailure,
  subscribeUserRequest,
  subscribeUserSuccess,
  subscribeUserFailure,
  fetchModelsTechDataRequest,
  fetchModelsTechDataSuccess,
  fetchModelsTechDataFailure,
  fetchModelsStandardEquipmentRequest,
  fetchModelsStandardEquipmentSuccess,
  fetchModelsStandardEquipmentFailure,
} from '../redux/actions/summaryActions';

import history from '../shared/helpers/history';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

export function fetchModelDataByConfigId(id) {
  const requestOptions = { method: 'GET' };

  return (dispatch) => {
    dispatch(fetchOrderDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/orders/summary/${id}${process.env.REACT_APP_USER_TOKEN}`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchOrderDataSuccess(res));
      })
      .catch((error) => {
        dispatch(fetchOrderDataFailure(error));
        history.push('/page-not-found');
      });
  };
}

export function sendEmail(params) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(params),
  };

  return (dispatch) => {
    dispatch(sendConfigurationByEmailRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/orders/mail${process.env.REACT_APP_USER_TOKEN}`, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(sendConfigurationByEmailSuccess(res.success));
      })
      .catch((error) => dispatch(sendConfigurationByEmailFailure(error)));
  };
}

export function subscribeUser(email) {
  const params = { email };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(params),
  };

  return (dispatch) => {
    dispatch(subscribeUserRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/newsletter${process.env.REACT_APP_USER_TOKEN}`, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(subscribeUserSuccess(res));
      })
      .catch((error) => dispatch(subscribeUserFailure(error)));
  };
}

export function fetchModelsTechData(id) {
  const requestOptions = { method: 'GET' };

  return (dispatch) => {
    dispatch(fetchModelsTechDataRequest());
    fetch(
      `${process.env.REACT_APP_BASE_URL}/orders/technical-data/${id}${process.env.REACT_APP_USER_TOKEN}`,
      requestOptions,
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchModelsTechDataSuccess(res));
      })
      .catch((error) => dispatch(fetchModelsTechDataFailure(error)));
  };
}

export function fetchModelsStandardEquipment(id) {
  const requestOptions = { method: 'GET' };

  return (dispatch) => {
    dispatch(fetchModelsStandardEquipmentRequest());
    fetch(
      `${process.env.REACT_APP_BASE_URL}/orders/standard-equipment/${id}${process.env.REACT_APP_USER_TOKEN}`,
      requestOptions,
    )
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchModelsStandardEquipmentSuccess(res));
      })
      .catch((error) => dispatch(fetchModelsStandardEquipmentFailure(error)));
  };
}
