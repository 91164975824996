export const FETCH_ORDER_DATA_REQUEST = 'FETCH_ORDER_DATA_REQUEST';
export const FETCH_ORDER_DATA_SUCCESS = 'FETCH_ORDER_DATA_SUCCESS';
export const FETCH_ORDER_DATA_FAILURE = 'FETCH_ORDER_DATA_FAILURE';
export const CREATE_CONFIGURATION_REQUEST = 'CREATE_CONFIGURATION_REQUEST';
export const CREATE_CONFIGURATION_SUCCESS = 'CREATE_CONFIGURATION_SUCCESS';
export const CREATE_CONFIGURATION_FAILURE = 'CREATE_CONFIGURATION_FAILURE';
export const SEND_CONFIGURATION_BY_EMAIL_REQUEST = 'SEND_CONFIGURATION_BY_EMAIL_REQUEST';
export const SEND_CONFIGURATION_BY_EMAIL_SUCCESS = 'SEND_CONFIGURATION_BY_EMAIL_SUCCESS';
export const SEND_CONFIGURATION_BY_EMAIL_FAILURE = 'SEND_CONFIGURATION_BY_EMAIL_FAILURE';
export const SUBSCRIBE_USER_REQUEST = 'SUBSCRIBE_USER_REQUEST';
export const SUBSCRIBE_USER_SUCCESS = 'SUBSCRIBE_USER_SUCCESS';
export const SUBSCRIBE_USER_FAILURE = 'SUBSCRIBE_USER_FAILURE';
export const FETCH_MODELS_TECH_DATA_REQUEST = 'FETCH_MODELS_TECH_DATA_REQUEST';
export const FETCH_MODELS_TECH_DATA_SUCCESS = 'FETCH_MODELS_TECH_DATA_SUCCESS';
export const FETCH_MODELS_TECH_DATA_FAILURE = 'FETCH_MODELS_TECH_DATA_FAILURE';
export const FETCH_MODELS_STANDARD_EQUIPMENT_REQUEST = 'FETCH_MODELS_STANDARD_EQUIPMENT_REQUEST';
export const FETCH_MODELS_STANDARD_EQUIPMENT_SUCCESS = 'FETCH_MODELS_STANDARD_EQUIPMENT_SUCCESS';
export const FETCH_MODELS_STANDARD_EQUIPMENT_FAILURE = 'FETCH_MODELS_STANDARD_EQUIPMENT_FAILURE';

export function createConfigurationRequest() {
  return {
    type: CREATE_CONFIGURATION_REQUEST,
  };
}

export function createConfigurationSuccess(id) {
  return {
    type: CREATE_CONFIGURATION_SUCCESS,
    payload: id,
  };
}

export function createConfigurationFailure(error) {
  return {
    type: CREATE_CONFIGURATION_FAILURE,
    payload: error,
  };
}

export function fetchOrderDataRequest() {
  return {
    type: FETCH_ORDER_DATA_REQUEST,
  };
}

export function fetchOrderDataSuccess(data) {
  return {
    type: FETCH_ORDER_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchOrderDataFailure(error) {
  return {
    type: FETCH_ORDER_DATA_FAILURE,
    payload: error,
  };
}

export function sendConfigurationByEmailRequest() {
  return {
    type: SEND_CONFIGURATION_BY_EMAIL_REQUEST,
  };
}

export function sendConfigurationByEmailSuccess(data) {
  return {
    type: SEND_CONFIGURATION_BY_EMAIL_SUCCESS,
    payload: data,
  };
}

export function sendConfigurationByEmailFailure(error) {
  return {
    type: SEND_CONFIGURATION_BY_EMAIL_FAILURE,
    payload: error,
  };
}

export function subscribeUserRequest() {
  return {
    type: SUBSCRIBE_USER_REQUEST,
  };
}

export function subscribeUserSuccess(data) {
  return {
    type: SUBSCRIBE_USER_SUCCESS,
    payload: data,
  };
}

export function subscribeUserFailure(error) {
  return {
    type: SUBSCRIBE_USER_FAILURE,
    payload: error,
  };
}

export function fetchModelsTechDataRequest() {
  return {
    type: FETCH_MODELS_TECH_DATA_REQUEST,
  };
}

export function fetchModelsTechDataSuccess(data) {
  return {
    type: FETCH_MODELS_TECH_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchModelsTechDataFailure(error) {
  return {
    type: FETCH_MODELS_TECH_DATA_FAILURE,
    payload: error,
  };
}

export function fetchModelsStandardEquipmentRequest() {
  return {
    type: FETCH_MODELS_STANDARD_EQUIPMENT_REQUEST,
  };
}

export function fetchModelsStandardEquipmentSuccess(data) {
  return {
    type: FETCH_MODELS_STANDARD_EQUIPMENT_SUCCESS,
    payload: data,
  };
}

export function fetchModelsStandardEquipmentFailure(error) {
  return {
    type: FETCH_MODELS_STANDARD_EQUIPMENT_FAILURE,
    payload: error,
  };
}
