import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../Icon/closeIcon';

const EquipmentInfo = ({
  onRemoveClick, isClickable, data, modelInfo, currentPage,
}) => {
  let requiredMultiModules;
  let requiredModulesIds;
  let selectedEquipment;
  let allRequiredModulesSelected;
  let requiredMultiModulesSelected;
  if (currentPage !== 'summary') {
    requiredMultiModules = modelInfo.modules.filter((module) => (
      module.required_one_or_more.length > 0
    ));
    requiredModulesIds = requiredMultiModules.map((item) => item.id);
    selectedEquipment = data.modules.map((item) => item.id);
    allRequiredModulesSelected = requiredModulesIds.every(
      (requiredModulesId) => selectedEquipment.includes(requiredModulesId),
    );

    requiredMultiModulesSelected = requiredMultiModules.filter((module) => data.modules.includes(module));
  }
  return (
    <div className="selected-equipment-list">
      <ul className="info-list-item">
        {/* Loop through multi modules dependencies */}
        {currentPage !== 'summary' && modelInfo.slug.includes('aerys') && requiredMultiModules.length > 0
        && requiredMultiModulesSelected.map((item) => (
        // show only visible modules selected (e.g. user do not need to see Nvidia module)
          !!item.is_visible
          && (
          <li key={item.id}>
            {allRequiredModulesSelected
            && (
              <button
                className={`${currentPage === 'equipment' ? 'close-btn' : 'hidden'}`}
                type="button"
                onClick={() => onRemoveClick(item)}
              >
                <CloseIcon />
              </button>
            )}
            <span>{item.name}</span>
            {/* <span className="price right">{item.price_formatted} €</span> */}
          </li>
          )

        ))}
        {currentPage !== 'summary'
          ? data.modules.map((item) => (
            // show only visible modules selected (e.g. user do not need to see Nvidia module) and display multi modules
            !!item.is_visible && item.required_one_or_more && item.required_one_or_more.length === 0
          && (
          <li key={item.id}>
            {isClickable
            && (
              <button className="close-btn" type="button" onClick={() => onRemoveClick(item)}>
                <CloseIcon />
              </button>
            )}
            <span>{item.name}</span>
            {/* <span className="price right">{item.price_formatted} €</span> */}
          </li>
          )
          ))
          : data.modules.map((item) => (
          // show only visible modules selected (e.g. user do not need to see Nvidia module)
            !!item.is_visible
            && (
            <li key={item.id}>
              {isClickable
              && (
                <button className="close-btn" type="button" onClick={() => onRemoveClick(item)}>
                  <CloseIcon />
                </button>
              )}
              <span>{item.name}</span>
              {/* <span className="price right">{item.price_formatted} €</span> */}
            </li>
            )
          ))}
      </ul>
    </div>

  );
};

EquipmentInfo.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
  isClickable: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default EquipmentInfo;
