import {
  CREATE_CONFIGURATION_REQUEST,
  CREATE_CONFIGURATION_SUCCESS,
  CREATE_CONFIGURATION_FAILURE,
  FETCH_ORDER_DATA_REQUEST,
  FETCH_ORDER_DATA_SUCCESS,
  FETCH_ORDER_DATA_FAILURE,
  SEND_CONFIGURATION_BY_EMAIL_REQUEST,
  SEND_CONFIGURATION_BY_EMAIL_SUCCESS,
  SEND_CONFIGURATION_BY_EMAIL_FAILURE,
  SUBSCRIBE_USER_REQUEST,
  SUBSCRIBE_USER_SUCCESS,
  SUBSCRIBE_USER_FAILURE,
  FETCH_MODELS_TECH_DATA_REQUEST,
  FETCH_MODELS_TECH_DATA_SUCCESS,
  FETCH_MODELS_TECH_DATA_FAILURE,
  FETCH_MODELS_STANDARD_EQUIPMENT_REQUEST,
  FETCH_MODELS_STANDARD_EQUIPMENT_SUCCESS,
  FETCH_MODELS_STANDARD_EQUIPMENT_FAILURE,
} from '../actions/summaryActions';

const initialState = {
  error: '',
  loading: false,
  selectedModelConfigId: null,
  selectedModelData: {},
  emailSendResponse: '',
  subscriptionResponse: '',
  techSpecsData: [],
  standardEquipmentData: {},
};

function summaryReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        selectedModelConfigId: action.payload,
        loading: false,
      };
    case CREATE_CONFIGURATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ORDER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDER_DATA_SUCCESS:
      return {
        ...state,
        selectedModelData: action.payload,
        loading: false,
      };
    case FETCH_ORDER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_CONFIGURATION_BY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_CONFIGURATION_BY_EMAIL_SUCCESS:
      return {
        ...state,
        emailSendResponse: action.payload,
        loading: false,
      };
    case SEND_CONFIGURATION_BY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUBSCRIBE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        subscriptionResponse: action.payload,
        loading: false,
      };
    case SUBSCRIBE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MODELS_TECH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MODELS_TECH_DATA_SUCCESS:
      return {
        ...state,
        techSpecsData: action.payload,
        loading: false,
      };
    case FETCH_MODELS_TECH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MODELS_STANDARD_EQUIPMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MODELS_STANDARD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        standardEquipmentData: action.payload,
        loading: false,
      };
    case FETCH_MODELS_STANDARD_EQUIPMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default summaryReducer;
