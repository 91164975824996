import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import CustomButton from '../../../components/Button';

const ModelSelection = ({ models, product }) => {
  const twoColGrid = [2, 4, 8, 10];
  // if number of models to display equals 1, 2, 4, 8 or 10 we should display 2 models per view
  // othervise we display three models per view

  let layoutGrid = twoColGrid.includes(models.length) ? 'col-lg-6' : 'col-lg-4';

  // check if there is only one product to display it on center
  if (models.length === 1) layoutGrid = 'col-lg-12';

  let imageOrientation;
  let productType;
  switch (product) {
    case 'benches':
      imageOrientation = 'landscape';
      productType = 'benches';
      break;
    case 'waste-containers':
      imageOrientation = 'portrait';
      productType = 'waste-containers';
      break;
    case 'aerys':
      imageOrientation = 'landscape';
      productType = 'aerys';
      break;
    default:
      imageOrientation = 'landscape';
      break;
  }

  return (
    <div className={`model-selection__list ${models.length < 4 && 'full-height'}`}>
      {models.map((model) => (
        <Col className={`${layoutGrid} model-selection__wrap ${imageOrientation}`} key={model.id}>
          <div className="model-selection__container">
            <h2>{model.name}</h2>
            <div className={`model-selection__image ${imageOrientation}`}>
              <img src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${model.slug}.png`} alt={model.name} />
            </div>
            <div className={`${imageOrientation} model-selection__btn`}>
              <CustomButton link={`/model/${model.slug}`} />
            </div>
          </div>
        </Col>
      ))}
    </div>
  );
};

ModelSelection.propTypes = {
  models: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  product: PropTypes.string.isRequired,
};

export default ModelSelection;
