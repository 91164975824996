import React from 'react';
import PropTypes from 'prop-types';

const ArrowIcon = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill={color}>
    <path
      d="M10.9375 18.25C10.5233 18.25 10.1875 17.9142 10.1875 17.5C10.1875
    17.0858 10.5233 16.75 10.9375 16.75V18.25ZM24.5928 16.9697C24.8857 17.2626
    24.8857 17.7374 24.5928 18.0303L19.8199 22.8033C19.527 23.0962 19.0521 23.0962
    18.7592 22.8033C18.4663 22.5104 18.4663 22.0355 18.7592 21.7426L23.0018 17.5L18.7592
    13.2574C18.4663 12.9645 18.4663 12.4896 18.7592 12.1967C19.0521 11.9038 19.527 11.9038
    19.8199 12.1967L24.5928 16.9697ZM10.9375 16.75H24.0625V18.25H10.9375V16.75Z"
    />
  </svg>
);

ArrowIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ArrowIcon;
