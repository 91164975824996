import React from 'react';
import PropTypes from 'prop-types';
import OrderingMenu from '../../../components/OverviewMenu/OrderingMenu';
import Gallery from '../../../components/Gallery';
import ColorPicker from '../../../components/ColorPicker';

const ColorsConfigurator = ({
  modelInfo, handleNextButton, handleColorSelect, handleBackButton, selectedColor,
}) => (
  <>
    <OrderingMenu
      handleNextButton={(event) => handleNextButton(event, 'accessories')}
      handleBackButton={(event) => handleBackButton(event, 'home')}
    />
    <h1>Choose your look</h1>
    <Gallery modelInfo={modelInfo} />
    {modelInfo.colors && modelInfo.colors.length > 0
    && <ColorPicker colorsList={modelInfo.colors} onColorSelect={handleColorSelect} selectedColor={selectedColor} />}
  </>
);

ColorsConfigurator.propTypes = {
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  selectedColor: PropTypes.number.isRequired,
  handleNextButton: PropTypes.func.isRequired,
  handleColorSelect: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
};

export default ColorsConfigurator;
