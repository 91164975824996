// Fetch list of all products (benches, waste containers, etc.)
import {
  fetchProductsRequest,
  fetchProductsSuccess,
  fetchProductsFailure,
} from '../redux/actions/productsActions';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

const requestOptions = { method: 'GET' };

export default function fetchProducts() {
  return (dispatch) => {
    dispatch(fetchProductsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/product-types${process.env.REACT_APP_USER_TOKEN}`, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchProductsSuccess(res));
      })
      .catch((error) => dispatch(fetchProductsFailure(error)));
  };
}
