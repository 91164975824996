import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseModalIcon from '../../Icon/closeModalIcon';

const EquipmentModal = ({
  modalOpen, onCloseClick, standardEquipment,
}) => (
  <>
    <Modal
      isOpen={modalOpen}
      scrollable
    >
      <div className="modal__header fixed-header">
        <button className="close-modal-btn" type="button" onClick={() => onCloseClick()}> <CloseModalIcon /></button>
        <h3 className="text-modal  modal__title">Equipment data </h3>
      </div>
      <div className="modal__body scrollable-body">
        <h4>Complete list of equipment data</h4>
        <ul className="tech-list">
          {
              standardEquipment.accessories.map((item) => (
                <li key={item.id}>
                  <div className="col-12 p-0">
                    <div className="col-2 tech-list__icon p-0">
                      <img
                        src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}technical-data-icons/${item.icon}`}
                        alt={item.icon}
                      />
                    </div>
                    <div className="col-5 tech-list__key p-0">{item.name}</div>
                    <div className="col-5 tech-list__value">
                      {
                        item.description_line_items.map((description) => (
                          <p key={description}>{description}</p>
                        ))
                    }
                    </div>
                  </div>
                </li>
              ))
            }
          {
            standardEquipment.modules.map((item) => (
              <li key={item.id}>
                <div className="col-12 p-0">
                  <div className="col-2 tech-list__icon p-0">
                    <img
                      src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}technical-data-icons/${item.icon} `}
                      alt={item.icon}
                    />
                  </div>
                  <div className="col-5 tech-list__key p-0">{item.name}</div>
                  <div className="col-5 tech-list__value">{item.description}</div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </Modal>
  </>
);

EquipmentModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  standardEquipment: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
};

export default EquipmentModal;
