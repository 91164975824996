import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import AddIcon from '../Icon/addIcon';
import DownloadIcon from '../Icon/downloadIcon';
import RightIcon from '../Icon/arrowRightIcon';
import LeftIcon from '../Icon/arrowLeftIcon';

class CustomButton extends PureComponent {
  render() {
    const {
      link, text, color, icon, disabled, padding, onButtonClick, customClass,
    } = this.props;

    const styles = { padding };
    let colorClass = '';

    if (color === 'white') colorClass = 'light';
    else if (color === 'gray') colorClass = 'gray';

    return (
      <Button className={`rounded--button ${colorClass} ${customClass}`} disabled={disabled} onClick={onButtonClick}>
        <a href={link} style={styles}>
          {icon === 'leftArrow' && (
          <LeftIcon color="white" />
          )}
          {text}
          {icon === 'add' && (
            <AddIcon color="gray" />
          )}
          {icon === 'download' && (
            <DownloadIcon color="white" />
          )}
          {icon === 'rightArrow' && (
            <RightIcon color="white" />
          )}
        </a>
      </Button>
    );
  }
}

CustomButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  padding: PropTypes.string,
  onButtonClick: PropTypes.func,
  customClass: PropTypes.string,
};

CustomButton.defaultProps = {
  text: 'Customize',
  color: 'black',
  icon: '',
  disabled: false,
  padding: '14px 22px',
  link: '#',
  onButtonClick: () => {},
  customClass: '',
};

export default CustomButton;
