// PRICES - code commented for NOW
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import CustomButton from '../Button';
import ModelInfo from './components/ModelInfo';
import AccessoriesInfo from './components/AccessoriesInfo';
import PriceInfo from './components/PriceInfo';

import {
  setModelQuantity,
  removeModelMechanicalAccesssories,
  removeModelMechanicalEquipment,
  removeItemFromRequiredInternetList,
} from '../../redux/actions/configuratorActions';
import {
  setOverviewQuantity,
  removeOverviewMechanicalAccesssories,
  removeOverviewMechanicalEquipment,
} from '../../redux/actions/overviewActions';

class OrderingMenu extends Component {
  constructor() {
    super();
    this.state = {
      validationError: '',
    };
  }

  handleChangeQuantity = (e) => {
    const { dispatch } = this.props;

    dispatch(setOverviewQuantity(parseInt(e.target.value, 10)));
    dispatch(setModelQuantity(parseInt(e.target.value, 10)));
  }

  handleEquipmentRemove = (equipment) => {
    const { dispatch, modelInfo } = this.props;

    if (equipment.requires_internet) dispatch(removeItemFromRequiredInternetList(equipment.id));

    dispatch(removeModelMechanicalEquipment(equipment.id));
    dispatch(removeOverviewMechanicalEquipment(equipment.id));

    // also remove other modules that this module requires to work
    if (equipment.requires_module.length > 0) {
      equipment.requires_module.forEach((item) => {
        const requiredModuleToRemove = modelInfo.modules.find((module) => module.id === item);

        dispatch(removeModelMechanicalEquipment(requiredModuleToRemove.id));
        dispatch(removeOverviewMechanicalEquipment(requiredModuleToRemove.id));
      });
    }

    // check if some other modules require this module to work - remove them also
    modelInfo.modules.forEach((item) => {
      if (item.requires_module.includes(equipment.id)) {
        dispatch(removeModelMechanicalEquipment(item.id));
        dispatch(removeOverviewMechanicalEquipment(item.id));
        // remove it also from required internet modules
        dispatch(removeItemFromRequiredInternetList(item.id));
      }
    });
  }

  handleAccessoryRemove = (accessory) => {
    const { dispatch } = this.props;

    dispatch(removeModelMechanicalAccesssories(accessory.id));

    if (accessory.requires_internet) dispatch(removeItemFromRequiredInternetList(accessory.id));
    dispatch(removeOverviewMechanicalAccesssories(accessory.id));
  }

  nextButtonClick = (e) => {
    const { handleNextButton, overviewData } = this.props;

    if (overviewData.quantity < 1) {
      this.setState({ validationError: 'Quantity must be positive number.' });
    } else if (!overviewData.quantity) {
      this.setState({ validationError: 'Quantity must be entered.' });
    } else {
      handleNextButton(e);
    }
  }

  render() {
    const {
      handleBackButton,
      overviewData,
      modelInfo,
      accessoryOptionIsRemovable,
      equipmentOptionIsRemovable,
      connectionOptionIsRemovable,
      configurator,
      summary,
    } = this.props;
    const { configPage, connectionTypeSelected } = configurator;

    const { validationError } = this.state;

    // const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // let pricePerUnit = overviewData.basic_model_price;
    // const colorPrice = overviewData.color.price;
    // let accessoriesPrice = 0;
    // let modulesPrice = 0;

    // if (overviewData.accessories.length > 0) {
    //   overviewData.accessories.forEach((accessory) => {
    //     accessoriesPrice += accessory.price;
    //   });
    // }
    // if (overviewData.modules.length > 0) {
    //   overviewData.modules.forEach((module) => {
    //     modulesPrice += module.price;
    //   });
    // }

    // pricePerUnit += colorPrice + accessoriesPrice + modulesPrice;
    // pricePerUnit /= 100;
    // pricePerUnit = pricePerUnit.toFixed(2);

    let btnText = 'Next';
    let isBtnDisabled = false;

    if (summary.loading) {
      btnText = 'Sending';
      isBtnDisabled = true;
    } else if (configPage === 'connection') {
      btnText = 'Finish';

      if (isEmpty(connectionTypeSelected)) isBtnDisabled = true;
    } else if (configPage === 'equipment' && configurator.modulesThatRequireInternet.length === 0) {
      btnText = 'Finish';
    }

    return (
      <div className="overview-menu-container">
        <div className="title">
          <h2>Overview</h2>

        </div>
        {modelInfo.name && <ModelInfo name={modelInfo.name} slug={modelInfo.slug} />}
        {modelInfo.price_formatted && (
        <AccessoriesInfo
          onRemoveEquipmentClick={this.handleEquipmentRemove}
          onRemoveAccessoryClick={this.handleAccessoryRemove}
          data={overviewData}
          basicPrice={modelInfo.price_formatted}
          equipmentOptionIsRemovable={equipmentOptionIsRemovable}
          accessoryOptionIsRemovable={accessoryOptionIsRemovable}
          connectionOptionIsRemovable={connectionOptionIsRemovable}
          modelInfo={modelInfo}
          currentPage={configurator.configPage}
        />
        )}
        <PriceInfo
          changeQuantity={this.handleChangeQuantity}
          // pricePerUnit={pricePerUnit}
          quantity={overviewData.quantity}
        />
        {validationError && <span className="input-error"> {validationError} </span>}
        <div className="overview-menu__button-wrapper">
          <div className="back-btn">
            <CustomButton padding="10px" text="back" onButtonClick={handleBackButton} />
          </div>
          <div className="next-btn">
            <CustomButton
              padding="10px"
              text={btnText}
              disabled={isBtnDisabled}
              onButtonClick={this.nextButtonClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

OrderingMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleNextButton: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  overviewData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  configurator: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  summary: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  accessoryOptionIsRemovable: PropTypes.bool,
  equipmentOptionIsRemovable: PropTypes.bool,
  connectionOptionIsRemovable: PropTypes.bool,
};

OrderingMenu.defaultProps = {
  accessoryOptionIsRemovable: false,
  equipmentOptionIsRemovable: false,
  connectionOptionIsRemovable: false,
};

const mapStateToProps = (state) => ({
  overviewData: state.overview.overviewData,
  modelInfo: state.models.modelInfo,
  configurator: state.configurator,
  summary: state.summary,
});

export default connect(mapStateToProps)(OrderingMenu);
