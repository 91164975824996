import React from 'react';
import PropTypes from 'prop-types';
import HeaderMain from './headerMain';
import HeaderConfigurator from './headerConfigurator';

const Header = ({
  headerType,
  products,
  onChange,
  selected,
}) => (
  <header className="header-container">
    {headerType === 'main'
        && (<HeaderMain productsList={products} activeProduct={selected} onNavClick={onChange} />)}
    {headerType === 'configurator'
        && (<HeaderConfigurator selected={selected} />)}
  </header>
);

Header.propTypes = {
  headerType: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))),
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Header.defaultProps = {
  products: [],
  onChange: () => {},
};

export default Header;
