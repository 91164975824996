import React from 'react';
import PropTypes from 'prop-types';

const DownloadIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
    <path
      d="M11 4.875C11 4.32271 11.4477 3.875 12 3.875C12.5523
      3.875 13 4.32271 13 4.875L11 4.875ZM12.7071 18.7071C12.3166
      19.0976 11.6834 19.0976 11.2929 18.7071L4.92893 12.3431C4.53841
      11.9526 4.53841 11.3195 4.92893 10.9289C5.31946 10.5384 5.95262
      10.5384 6.34315 10.9289L12 16.5858L17.6569 10.9289C18.0474 10.5384
      18.6805 10.5384 19.0711 10.9289C19.4616 11.3195 19.4616 11.9526 19.0711
      12.3431L12.7071 18.7071ZM13 4.875L13 18L11 18L11 4.875L13 4.875Z"
    />
  </svg>
);

DownloadIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default DownloadIcon;
