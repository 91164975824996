import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import '../../styles/app.scss';
import MyRouter from './Router';
import store from './store';
import { config as i18nextConfig } from '../../translations';
import Loading from '../../shared/components/LoadingSpinner';
import history from '../../shared/helpers/history';

i18next.init(i18nextConfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <Router history={history}>
          <I18nextProvider i18n={i18next}>
            <>
              {!loaded
                && (
                  <Loading loading={loading} />
                )}
              <MyRouter />
            </>
          </I18nextProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;
