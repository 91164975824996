/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseModalIcon from '../../Icon/closeModalIcon';

const TechSpecsModal = ({
  modalOpen, onCloseClick, techSpecs,
}) => (
  <>
    <Modal
      isOpen={modalOpen}
      scrollable
    >
      <div className="modal__header fixed-header">
        <button className="close-modal-btn" type="button" onClick={() => onCloseClick()}> <CloseModalIcon /></button>
        <h3 className="text-modal  modal__title">Technical data</h3>
      </div>
      <div className="modal__body scrollable-body">
        <h4>Complete list of technical data</h4>
        <ul className="tech-list">
          {
            techSpecs.map((item, key) => (
              <li key={key}>
                <div className="col-12 p-0">
                  <div className="col-6 tech-list__key p-0">{item.name}</div>
                  <div className="col-6 tech-list__value">
                    {
                        item.value.map((values) => (
                          <p key={values}>{values}</p>
                        ))
                    }
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </Modal>
  </>
);

TechSpecsModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  techSpecs: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
};

export default TechSpecsModal;
