import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../Button';

const Connection = ({ connectionList, onConnectionSelect, connectionTypeSelected }) => (
  <div className="equipment-picker">
    <ul>
      {connectionList.map((item) => (
        item.module_category_id === 1
          && (
          <li key={item.id}>
            <div className="modul-picture">
              <div className="main-image">
                <img
                  src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}modules/${item.icon}`}
                  alt={item.icon}
                />
              </div>
            </div>
            <div className="modul-info">
              <h2 className="modul-title">{item.name}</h2>
              <p className="modul-description">{item.description}</p>
            </div>
            <div className="modul-btn">
              <CustomButton
                text={connectionTypeSelected.id === item.id ? 'Added' : 'Add'}
                icon="add"
                padding="3px 15px"
                customClass={`${connectionTypeSelected.id === item.id ? 'button-active' : ''}`}
                onButtonClick={(event) => onConnectionSelect(event, item)}
                disabled={(connectionTypeSelected.id === item.id)}
              />
            </div>
          </li>
          )
      ))}
    </ul>
  </div>
);

Connection.propTypes = {
  connectionList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onConnectionSelect: PropTypes.func.isRequired,
  connectionTypeSelected: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
};

export default Connection;
