import React from 'react';
import PropTypes from 'prop-types';

const AddIcon = ({ color }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill={color}>
    <path d="M12 7H7V12H5V7H0V5H5V0H7V5H12V7Z" />
  </svg>
);

AddIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AddIcon;
