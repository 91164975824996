import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModelSelection from './components/ModelSelection';
import Header from '../../components/Header';

import fetchProducts from '../../api/productsApi';
import { fetchModels } from '../../api/modelsApi';

class Homepage extends Component {
  constructor() {
    super();
    this.state = {
      productSelected: 'benches',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { productSelected } = this.state;
    dispatch(fetchProducts());
    dispatch(fetchModels(productSelected));
  }

  handleProductChange = (event, productType) => {
    event.preventDefault();
    const { dispatch } = this.props;

    dispatch(fetchModels(productType));
    this.setState({
      productSelected: productType,
    });
  }

  render() {
    const { productsList, modelsList } = this.props;
    const { productSelected } = this.state;

    return (
      <>
        {productsList.length > 0 && (
        <Header
          headerType="main"
          products={productsList}
          selected={productSelected}
          onChange={this.handleProductChange}
        />
        )}
        <Container className="homepage">
          {modelsList.length > 0 && <ModelSelection models={modelsList} product={productSelected} /> }
        </Container>
      </>
    );
  }
}

Homepage.propTypes = {
  productsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  modelsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  productsList: state.products.productsList,
  modelsList: state.models.modelsList,
});

export default connect(mapStateToProps)(Homepage);
