/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './containers/App/App';

if (!process.env.NODE_ENV === 'development') {
  Sentry.init({ dsn: 'http://d5971717ac6e470d9cb2d1a2f6a2aade@172.16.100.20:9000/10' });
}

render(
  <App />,
  document.getElementById('root'),
);
