/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';

class Gallery extends Component {
  constructor() {
    super();
    this.state = {
      accessoriesSelected: '0',
    };
  }

  componentDidMount() {
    // TODO privremeno rijesenje dok su nam gallerije slike po folderima
    // u buducnosti cemo imat api za slike
    const { page, selectedModelData, overviewData } = this.props;

    if (page === 'summaryPage') {
      const accessoriesArray = selectedModelData.deployed_products[0].selected_accessories.length > 0
        ? selectedModelData.deployed_products[0].selected_accessories.map((item) => item.id) : [];

      // jedini moduli koji ZASAD utjecu na galleriju su oni sa ekranom i solar za aerys, razmislit kako rijesit
      const moduleFolderId = (selectedModelData.deployed_products[0].selected_modules.length > 0 && selectedModelData.deployed_products[0].selected_modules[0].module_id === 38 ? 38 : 9);
      if (selectedModelData.deployed_products[0].selected_modules.some((module) => module.has_images)) {
        accessoriesArray.push(moduleFolderId);
      }

      this.sortAccessoriesAsc(accessoriesArray);
    } else {
      const accessoriesArray = overviewData.accessories.length > 0
        ? overviewData.accessories.map((item) => item.id) : [];

      // jedini moduli koji ZASAD utjecu na galleriju su oni sa ekranom i solar za aerys, razmislit kako rijesit
      const moduleFolderId = (overviewData.modules.length > 0 && overviewData.modules[0].id === 38 ? 38 : 9);
      if (overviewData.modules.some((module) => module.has_images)) {
        accessoriesArray.push(moduleFolderId);
      }

      this.sortAccessoriesAsc(accessoriesArray);
    }
  }

  componentDidUpdate(prevProps) {
    const { overviewData, page } = this.props;

    if (page === 'configuratorPage' && prevProps.overviewData.accessories !== overviewData.accessories) {
      const accessoriesArray = overviewData.accessories.length > 0
        ? overviewData.accessories.map((item) => item.id) : [];
      this.sortAccessoriesAsc(accessoriesArray);
    }
  }

  substringRalFromColorName = (name) => {
    // Remove "RAL " chars from color name
    const shortenName = name.substring(4);
    return shortenName;
  };

  joinAccessoryID = (list) => {
    if (list.length > 0) {
      const joinedAccessories = list ? list.join('-') : '0';
      this.setState({
        accessoriesSelected: joinedAccessories,
      });
    } else {
      this.setState({
        accessoriesSelected: '0',
      });
    }
  };

  sortAccessoriesAsc = (list) => {
    // sort ID numbers ascending
    if (list.length > 0) list.sort((a, b) => a - b);
    this.joinAccessoryID(list);
  };

  render() {
    const {
      overviewData,
      modelInfo,
      page,
      selectedModelData,
    } = this.props;
    const { accessoriesSelected } = this.state;
    let colorFolderName = '9001';
    let modelSlug = 'steora-classic';

    if (page === 'summaryPage') {
      colorFolderName = selectedModelData.id
        && this.substringRalFromColorName(selectedModelData.deployed_products[0].color.name);
      modelSlug = selectedModelData.id && selectedModelData.deployed_products[0].slug;
    } else {
      colorFolderName = overviewData.color.name && this.substringRalFromColorName(overviewData.color.name);
      modelSlug = modelInfo.slug || 'steora-classic';
    }

    let productType = 'waste-containers';
    if (modelSlug.includes('steora'))productType = 'benches';
    if (modelSlug.includes('aerys'))productType = 'aerys';

    const windModule = selectedModelData?.deployed_products?.[0]?.selected_modules?.filter((item) => item.module_id === 44);
    const hasWindModule = windModule?.length > 0;

    return (
      <Carousel
        emulateTouch
        swipeable
        infiniteLoop
      >
        <div>
          <img
            src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${colorFolderName}/${modelSlug}/${hasWindModule ? `${accessoriesSelected}-44` : accessoriesSelected}/1.png`}
            alt="slika"
          />
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${colorFolderName}/${modelSlug}/${hasWindModule ? `${accessoriesSelected}-44` : accessoriesSelected}/2.png`}
            alt="slika"
          />
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${colorFolderName}/${modelSlug}/${hasWindModule ? `${accessoriesSelected}-44` : accessoriesSelected}/3.png`}
            alt="slika"
          />
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${colorFolderName}/${modelSlug}/${hasWindModule ? `${accessoriesSelected}-44` : accessoriesSelected}/4.png`}
            alt="slika"
          />
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${colorFolderName}/${modelSlug}/${hasWindModule ? `${accessoriesSelected}-44` : accessoriesSelected}/5.png`}
            alt="slika"
          />
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${colorFolderName}/${modelSlug}/${hasWindModule ? `${accessoriesSelected}-44` : accessoriesSelected}/6.png`}
            alt="slika"
          />
        </div>
      </Carousel>
    );
  }
}

Gallery.propTypes = {
  overviewData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  selectedModelData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  page: PropTypes.string,
};

Gallery.defaultProps = {
  page: 'configuratorPage',
};

const mapStateToProps = (state) => ({
  overviewData: state.overview.overviewData,
  selectedModelData: state.summary.selectedModelData,
});

export default connect(mapStateToProps)(Gallery);
