/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '../Icon/checkIcon';
// const imageFolder = `${process.env.REACT_APP_UPLOADS_URL}/slike-nesto/`;

const ColorPicker = ({ colorsList, onColorSelect, selectedColor }) => (
  <div className="color-picker">
    <h2>Select a color:</h2>
    <ul className="color-list">{colorsList.map((item) => (
      <li key={item.id}>
        <button
          className="color-selector"
          style={{ background: `#${item.hex}` }}
          type="button"
          onClick={() => onColorSelect(item)}
        />
        <div className={selectedColor === item.id ? 'color-selected' : 'hidden'}>
          <CheckIcon />
        </div>
        <p>{item.name}</p>
      </li>
    ))}
    </ul>
  </div>
);

ColorPicker.propTypes = {
  colorsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onColorSelect: PropTypes.func.isRequired,
  selectedColor: PropTypes.number.isRequired,
};

export default ColorPicker;
