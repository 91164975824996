import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../Button';
import Modal from '../../Modal';

class ConfigurationID extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  openModal = (event) => {
    event.preventDefault();
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { modalOpen } = this.state;
    const { configID } = this.props;

    return (
      <>
        <div className="configuration-id">
          <h2>Configuration ID</h2>
          <input className="id-config" type="text" defaultValue={configID} disabled />
          <div className="save-btn">
            <div className="email-btn">
              <CustomButton text="save to email" padding="14px 18px" onButtonClick={this.openModal} />
            </div>
            <div className="pdf-btn">
              <CustomButton
                text="save pdf"
                icon="download"
                padding="14px"
                link={`${process.env.REACT_APP_BASE_URL}/orders/download/${configID}`}
              />
            </div>
          </div>
        </div>
        <Modal modalOpen={modalOpen} onCloseClick={() => this.closeModal()} modalType="email" />
      </>
    );
  }
}

ConfigurationID.propTypes = {
  configID: PropTypes.string.isRequired,
};

export default ConfigurationID;
