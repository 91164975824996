import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Header from '../../components/Header';
import history from '../../shared/helpers/history';
import ColorsConfigurator from './components/ColorsConfigurator';
import AccessoriesConfigurator from './components/AccessoriesConfigurator';
import EquipmentConfigurator from './components/EquipmentConfigurator';
import ConnectionsConfigurator from './components/ConnectionsConfigurator';
import Modal from '../../components/Modal';

import { fetchModelInfo } from '../../api/modelsApi';
import {
  setConfiguratorPage,
  setConfiguratorPageHeader,
  setModelColor,
  addModelMechanicalAccesssories,
  removeModelMechanicalAccesssories,
  addModelMechanicalEquipment,
  addItemToRequiredInternetList,
  removeItemFromRequiredInternetList,
  removeModelMechanicalEquipment,
  setConnectionTypeSelected,
} from '../../redux/actions/configuratorActions';
import {
  setOverviewColor,
  addOverviewMechanicalAccesssories,
  removeOverviewMechanicalAccesssories,
  addOverviewMechanicalEquipment,
  removeOverviewMechanicalEquipment,
  setPreselectedModule,
} from '../../redux/actions/overviewActions';
import { createConfigurationAction } from '../../api/configuratorApi';

class Configurator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      popUpData: {
        name: '',
        requires: [],
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const model = match.params.slug;
    dispatch(setConfiguratorPage('colors'));
    dispatch(setConfiguratorPageHeader('look'));

    if (model) dispatch(fetchModelInfo(model));
    else history.push('/');
  }

  handleNextButton = (event, pageToChange) => {
    event.preventDefault();
    const { dispatch, modelInfo, overviewData } = this.props;

    if (pageToChange === 'accessories' && modelInfo.slug.includes('aerys')) {
      const defaultAerysAccessory = modelInfo.accessories.find((accessories) => accessories.id === 19);
      const isAccessoryAlreadyAdded = modelInfo.accessories.some(
        (accessories) => overviewData.accessories.includes(accessories),
      );

      if (!isAccessoryAlreadyAdded) {
        dispatch(addModelMechanicalAccesssories(19));
        dispatch(addOverviewMechanicalAccesssories(defaultAerysAccessory));
      }
    }

    if (pageToChange === 'equipment') dispatch(setConfiguratorPageHeader(pageToChange));
    dispatch(setConfiguratorPage(pageToChange));

    if (pageToChange === 'equipment' && modelInfo.slug.includes('aerys')) {
      const requiredMultiModules = modelInfo.modules.filter((module) => (
        module.required_one_or_more.length > 0
      ));

      const canIAddModules = overviewData.modules.some(
        (selectedEquipment) => requiredMultiModules.includes(selectedEquipment),
      );

      if (requiredMultiModules.length > 0 && !canIAddModules) {
        dispatch(addModelMechanicalEquipment(requiredMultiModules[0].id));
        dispatch(setPreselectedModule(requiredMultiModules[0]));
      }
    }
  }

  handleBackButton = (event, pageToChange) => {
    event.preventDefault();
    const { dispatch } = this.props;

    if (pageToChange === 'home') history.push('/');
    if (pageToChange === 'accessories') dispatch(setConfiguratorPageHeader('look'));
    dispatch(setConfiguratorPage(pageToChange));
  }

  handleCreateOrderButton = (event) => {
    event.preventDefault();
    const { dispatch, configurator } = this.props;
    const paramsForSelectedModel = configurator.selectedModel;

    dispatch(createConfigurationAction(paramsForSelectedModel));
  }

  handleColorSelect = (color) => {
    const { dispatch } = this.props;

    dispatch(setModelColor(color.id));
    dispatch(setOverviewColor(color));
  }

  handleAccessoriesClick = (event, accessory) => {
    event.preventDefault();
    const { dispatch, configurator } = this.props;

    if (accessory.requires_internet) {
      dispatch(addItemToRequiredInternetList(accessory.id));
    }

    // If this accessory is already selected - do REMOVE click
    if (configurator.selectedModel.accessory_ids.includes(accessory.id)) {
      dispatch(removeModelMechanicalAccesssories(accessory.id));
      dispatch(removeOverviewMechanicalAccesssories(accessory.id));
    } else { // Else ADD click
      // Add selected accessory
      dispatch(addModelMechanicalAccesssories(accessory.id));
      dispatch(addOverviewMechanicalAccesssories(accessory));

      // Check ih that accessory excludes others
      if (accessory.excludes.length > 0) {
        accessory.excludes.forEach((item) => {
          dispatch(removeModelMechanicalAccesssories(item));
          dispatch(removeOverviewMechanicalAccesssories(item));
        });
      }
    }
  }

  handleEquipmentSelect = (event, equipment) => {
    event.preventDefault();
    const { dispatch, configurator, modelInfo } = this.props;

    // If this module is already selected - do REMOVE click
    if (configurator.selectedModel.module_ids.includes(equipment.id)) {
      dispatch(removeModelMechanicalEquipment(equipment.id));
      dispatch(removeOverviewMechanicalEquipment(equipment.id));

      // also remove other modules that this module requires to work
      if (equipment.requires_module.length > 0) {
        equipment.requires_module.forEach((item) => {
          const requiredModuleToRemove = modelInfo.modules.find((module) => module.id === item);

          dispatch(removeModelMechanicalEquipment(requiredModuleToRemove.id));
          dispatch(removeOverviewMechanicalEquipment(requiredModuleToRemove.id));
        });
      }

      // check if some other modules require this module to work - remove them also
      modelInfo.modules.forEach((item) => {
        if (item.requires_module.includes(equipment.id)) {
          dispatch(removeModelMechanicalEquipment(item.id));
          dispatch(removeOverviewMechanicalEquipment(item.id));
          // remove it also from required internet modules
          dispatch(removeItemFromRequiredInternetList(item.id));
        }
      });

      // remove it also from required internet modules
      if (equipment.requires_internet) dispatch(removeItemFromRequiredInternetList(equipment.id));
    } else { // Else ADD click
      // Add selected module
      dispatch(addModelMechanicalEquipment(equipment.id));
      dispatch(addOverviewMechanicalEquipment(equipment));

      // Check ih that module excludes others
      if (equipment.excludes.length > 0) {
        equipment.excludes.forEach((item) => {
          dispatch(removeModelMechanicalEquipment(item));
          dispatch(removeOverviewMechanicalEquipment(item));

          // remove it also from required internet modules
          dispatch(removeItemFromRequiredInternetList(item));
        });
      }

      // Check if that module requires some other modules to work - ADD them also
      if (equipment.requires_module.length > 0) {
        const popupData = {
          name: equipment.name,
          requires: [],
        };

        equipment.requires_module.forEach((item) => {
          const requiredToAdd = modelInfo.modules.find((module) => module.id === item);

          // Add it only if its not already added
          if (!configurator.selectedModel.module_ids.includes(requiredToAdd.id)) {
            // Add only optional modules - user doesn't need to know required modules
            if (!requiredToAdd.required) {
              dispatch(addModelMechanicalEquipment(requiredToAdd.id));
              dispatch(addOverviewMechanicalEquipment(requiredToAdd));
              // For auto adding modules, show in popup only visible modules in popup (e.g. do not Show Nvidia module)
              if (requiredToAdd.is_visible) popupData.requires.push(requiredToAdd.name);
              this.openModal(popupData);
            }
          }
        });
      }

      if (equipment.requires_internet) {
        dispatch(addItemToRequiredInternetList(equipment.id));
      }
    }
  }

  handleConnectionSelect = (event, connectionModule) => {
    event.preventDefault();
    const { dispatch, configurator } = this.props;

    dispatch(removeModelMechanicalEquipment(configurator.connectionTypeSelected.id));
    dispatch(removeOverviewMechanicalEquipment(configurator.connectionTypeSelected.id));

    dispatch(addModelMechanicalEquipment(connectionModule.id));
    dispatch(addOverviewMechanicalEquipment(connectionModule));

    dispatch(setConnectionTypeSelected(connectionModule));
  }

  handleNextButtonForEquipment = () => {
    const { dispatch, configurator } = this.props;
    const paramsForSelectedModel = configurator.selectedModel;

    // show "connection" page only if selected module or accessory requires internet connection
    if (configurator.modulesThatRequireInternet.length > 0) dispatch(setConfiguratorPage('connection'));
    else dispatch(createConfigurationAction(paramsForSelectedModel));
  }

  openModal = (data) => {
    this.setState({
      popUpData: data,
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { modelInfo, configurator, overviewData } = this.props;
    const { modalOpen, popUpData } = this.state;

    return (
      <>
        <Header
          headerType="configurator"
          selected={configurator.configPageHeader}
        />
        <Container className="configuration-page">
          {configurator.configPage === 'colors'
          && !isEmpty(overviewData.color)
          && (
            <ColorsConfigurator
              modelInfo={modelInfo}
              selectedColor={configurator.selectedModel.ral_color_id}
              handleNextButton={(event, page) => this.handleNextButton(event, page)}
              handleBackButton={(event, page) => this.handleBackButton(event, page)}
              handleColorSelect={(id) => this.handleColorSelect(id)}
            />
          )}
          {configurator.configPage === 'accessories' && (
          <AccessoriesConfigurator
            modelInfo={modelInfo}
            overviewData={overviewData}
            selectedAccessories={configurator.selectedModel.accessory_ids}
            handleNextButton={(event, page) => this.handleNextButton(event, page)}
            handleBackButton={(event, page) => this.handleBackButton(event, page)}
            handleAccessoriesClick={(event, item) => this.handleAccessoriesClick(event, item)}
          />
          )}
          {configurator.configPage === 'equipment' && (
          <EquipmentConfigurator
            modelInfo={modelInfo}
            selectedEquipment={configurator.selectedModel.module_ids}
            handleNextButtonForEquipment={this.handleNextButtonForEquipment}
            handleBackButton={(event, page) => this.handleBackButton(event, page)}
            handleEquipmentSelect={(event, item) => this.handleEquipmentSelect(event, item)}
          />
          )}
          {configurator.configPage === 'connection' && (
          <ConnectionsConfigurator
            modelInfo={modelInfo}
            handleCreateOrderButton={(event) => this.handleCreateOrderButton(event)}
            handleBackButton={(event, page) => this.handleBackButton(event, page)}
            handleConnectionSelect={(event, item) => this.handleConnectionSelect(event, item)}
            connectionTypeSelected={configurator.connectionTypeSelected}
          />
          )}
        </Container>
        {popUpData?.requires?.length > 0
        && (
        <Modal
          modalOpen={modalOpen}
          onCloseClick={() => this.closeModal()}
          modalType="required-modul"
          requiredModuleNames={popUpData}
        />
        )}
      </>
    );
  }
}

Configurator.propTypes = {
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  configurator: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.node,
    }).isRequired,
  }).isRequired,
  overviewData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
};

const mapStateToProps = (state) => ({
  modelInfo: state.models.modelInfo,
  configurator: state.configurator,
  overviewData: state.overview.overviewData,
});

export default connect(mapStateToProps)(Configurator);
