export const FETCH_MODELS_REQUEST = 'FETCH_MODELS_REQUEST';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_MODELS_FAILURE = 'FETCH_MODELS_FAILURE';
export const FETCH_MODEL_INFO_REQUEST = 'FETCH_MODEL_INFO_REQUEST';
export const FETCH_MODEL_INFO_SUCCESS = 'FETCH_MODEL_INFO_SUCCESS';
export const FETCH_MODEL_INFO_FAILURE = 'FETCH_MODEL_INFO_FAILURE';

export function fetchModelsRequest() {
  return {
    type: FETCH_MODELS_REQUEST,
  };
}

export function fetchModelsSuccess(models) {
  return {
    type: FETCH_MODELS_SUCCESS,
    payload: models,
  };
}

export function fetchModelsFailure(error) {
  return {
    type: FETCH_MODELS_FAILURE,
    payload: error,
  };
}

export function fetchModelInfoRequest() {
  return {
    type: FETCH_MODEL_INFO_REQUEST,
  };
}

export function fetchModelInfoSuccess(models) {
  return {
    type: FETCH_MODEL_INFO_SUCCESS,
    payload: models,
  };
}

export function fetchModelInfoFailure(error) {
  return {
    type: FETCH_MODEL_INFO_FAILURE,
    payload: error,
  };
}
