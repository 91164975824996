// PRICES - code commented for NOW
import React from 'react';
import PropTypes from 'prop-types';
// import { UncontrolledTooltip } from 'reactstrap';

const PriceInfo = ({
  changeQuantity,
  // pricePerUnit,
  quantity,
  disableTotalPriceUpdate,
}) => (
  <div className="product-price">
    {/* <div className="info-item" id="pricePerUnit">
      <span className="price-per-unit">Price (per unit)</span><span className="right">{pricePerUnit} €</span>
      <UncontrolledTooltip
        className="custom-tooltip"
        placement="top"
        target="pricePerUnit"
      >The prices shown are informative and may vary depending on the market. Customs costs, shipping and
        installation are not included in listed prices.
      </UncontrolledTooltip>
    </div>
    */}
    {disableTotalPriceUpdate
      ? (
        <>
          <div className="info-item">
            <span>Quantity of units</span>
            <span className="right">{quantity}</span>
          </div>
          {/* <div className="info-item" id="totalPrice">
            <span className="total-price">Total price<sup><b>i</b></sup></span>
            <UncontrolledTooltip
              className="custom-tooltip"
              placement="top"
              target="totalPrice"
            >The prices shown are informative and may vary depending on the market. Customs costs, shipping and
              installation are not included in listed prices.
            </UncontrolledTooltip>
            <span className="total-price right">{(pricePerUnit * quantity).toFixed(2)} €</span>
          </div> */}
        </>
      )
      : (
        <div className="info-item">
          <span>Quantity of units</span>
          <input
            className="right"
            type="number"
            min={1}
            value={quantity}
            onChange={changeQuantity}
          />
        </div>
      )}
  </div>
);

PriceInfo.propTypes = {
  changeQuantity: PropTypes.func,
  // pricePerUnit: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  disableTotalPriceUpdate: PropTypes.bool,
};

PriceInfo.defaultProps = {
  changeQuantity: () => {},
  disableTotalPriceUpdate: false,
};

export default PriceInfo;
