/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import OrderingMenu from '../../../components/OverviewMenu/OrderingMenu';
import Equipment from '../../../components/Equipment';

const EquipmentConfigurator = ({
  modelInfo, handleNextButtonForEquipment, handleEquipmentSelect, handleBackButton, selectedEquipment,
}) => (
  <>
    <OrderingMenu
      equipmentOptionIsRemovable
      handleNextButton={() => handleNextButtonForEquipment()}
      handleBackButton={(event) => handleBackButton(event, 'accessories')}
    />
    {modelInfo.slug.includes('steora') ? <h1>Equip your bench</h1>
      : modelInfo.slug.includes('terra') ? <h1>Equip your public can</h1>
        : <h1>Equip your Aerys</h1> }
    {modelInfo.modules && modelInfo.modules.length > 0 && (
      <Equipment
        equipmentList={modelInfo.modules}
        onEquipmentSelect={handleEquipmentSelect}
        selectedEquipment={selectedEquipment}
        modelInfo={modelInfo}
      />
    )}
  </>
);

EquipmentConfigurator.propTypes = {
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  selectedEquipment: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleNextButtonForEquipment: PropTypes.func.isRequired,
  handleEquipmentSelect: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
};

export default EquipmentConfigurator;
