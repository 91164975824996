import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LogoIcon from '../Icon/logoIcon';
import Modal from '../Modal';

class HeaderMain extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

openModal = () => {
  this.setState({ modalOpen: true });
};

closeModal = () => {
  this.setState({ modalOpen: false });
};

render() {
  const {
    modalOpen,
  } = this.state;

  const {
    productsList, activeProduct, onNavClick,
  } = this.props;

  return (
    <>
      <div className="header-main">
        <div className="row">
          <div className="col-lg-3 base-model p-0">
            <Link to={{ pathname: 'https://www.include.eu/' }} target="_blank">
              <LogoIcon />
            </Link>
            <h2>Select a base model</h2>
          </div>

          <nav className="col-lg-5 product-category p-0">
            {productsList.length > 0 && productsList.map((item) => (
              <h2 key={item.id}>
                <button
                  className={`product ${item.slug === activeProduct && 'active'} ${item.slug}`}
                  key={item.id}
                  onClick={(e) => onNavClick(e, item.slug)}
                  type="button"
                >{item.vanity_name}
                </button>
              </h2>
            ))}
          </nav>

          <div className="col-lg-4 load-configuration p-0">
            <h2>
              <button
                className="btn-configuration"
                onClick={() => this.openModal()}
                type="button"
              >Load saved configuration
              </button>
            </h2>
          </div>
        </div>
      </div>
      <Modal modalOpen={modalOpen} onCloseClick={() => this.closeModal()} modalType="configuration" />
    </>
  );
}
}

HeaderMain.propTypes = {
  productsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  activeProduct: PropTypes.string,
  onNavClick: PropTypes.func.isRequired,
};

HeaderMain.defaultProps = {
  activeProduct: 'benches',
};

export default HeaderMain;
