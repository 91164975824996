import React from 'react';
import PropTypes from 'prop-types';
import OrderingMenu from '../../../components/OverviewMenu/OrderingMenu';
import MehanicalAccessories from '../../../components/MehanicalAccessories';
import Gallery from '../../../components/Gallery';

const AestheticConfigurator = ({
  modelInfo,
  selectedAccessories,
  handleNextButton,
  handleAccessoriesClick,
  handleBackButton,
  overviewData,
}) => {
  let isAccessoryRemovable = true;
  if (modelInfo.slug === 'terra-public-can' || modelInfo.slug.includes('aerys')) isAccessoryRemovable = false;

  return (
    <>
      <OrderingMenu
        accessoryOptionIsRemovable={isAccessoryRemovable}
        handleNextButton={(event) => handleNextButton(event, 'equipment')}
        handleBackButton={(event) => handleBackButton(event, 'colors')}
      />
      <h1>Choose your look</h1>
      <Gallery modelInfo={modelInfo} />
      {modelInfo.accessories && modelInfo.accessories.length > 0
    && (
    <MehanicalAccessories
      accessoriesList={modelInfo.accessories}
      onAccessoriesClick={handleAccessoriesClick}
      selectedAccessories={selectedAccessories}
      overviewData={overviewData}
      modelInfo={modelInfo}
    />
    )}
    </>
  );
};

AestheticConfigurator.propTypes = {
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  selectedAccessories: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleNextButton: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleAccessoriesClick: PropTypes.func.isRequired,
  overviewData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
};

export default AestheticConfigurator;
