import {
  FETCH_MODELS_REQUEST,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_FAILURE,
  FETCH_MODEL_INFO_REQUEST,
  FETCH_MODEL_INFO_SUCCESS,
  FETCH_MODEL_INFO_FAILURE,
} from '../actions/modelsActions';

const initialState = {
  error: null,
  loading: false,
  modelsList: [],
  modelInfo: {},
};

function modelsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MODELS_REQUEST:
      return {
        ...state,
        modelsList: [],
        loading: true,
      };
    case FETCH_MODELS_SUCCESS:
      return {
        ...state,
        modelsList: action.payload,
        loading: false,
      };
    case FETCH_MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MODEL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MODEL_INFO_SUCCESS:
      return {
        ...state,
        modelInfo: action.payload,
        loading: false,
      };
    case FETCH_MODEL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default modelsReducer;
