const validate = (values) => {
  const errors = {};
  if (!values.configurationId) {
    errors.configurationId = 'Configuration ID field should not be empty';
  }
  if (values.configurationId && values.configurationId.length !== 12) {
    errors.configurationId = 'Configuration ID should be 12 characters long';
  }

  return errors;
};

export default validate;
