import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import CloseModalIcon from '../../Icon/closeModalIcon';
import CustomButton from '../../Button';

import validate from './emailValidator';

import { sendEmail, subscribeUser } from '../../../api/summaryApi';

class EmailModal extends Component {
  constructor() {
    super();
    this.state = {
      emailValue: '',
      validationError: '',
      checkboxValue: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    this.setState({
      emailValue: event.target.value,
      validationError: '',
    });
  }

  onCheckboxChange = () => {
    const { checkboxValue } = this.state;

    this.setState({
      checkboxValue: !checkboxValue,
    });
  }

  handleButtonClick = (event) => {
    event.preventDefault();
    const { dispatch, configID } = this.props;
    const { emailValue, checkboxValue } = this.state;
    const valuesToValidate = { emailValue };

    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      const params = {
        configuration_id: configID,
        email: emailValue,
      };
      dispatch(sendEmail(params));

      // if checkbox selected we should store users email
      if (checkboxValue) dispatch(subscribeUser(emailValue));
    } else {
      this.setState({ validationError: errors.emailValue });
    }
  }

  handleCloseButton = (e) => {
    e.preventDefault();
    const { onCloseClick } = this.props;
    onCloseClick();
  }

  render() {
    const { modalOpen, mailSendResponse, loading } = this.props;
    const { emailValue, validationError, checkboxValue } = this.state;

    return (
      <>
        <Modal
          className="email-modal"
          isOpen={modalOpen}
        >
          <div className="modal__header">
            {!mailSendResponse
            && (
            <button
              className="close-modal-btn"
              type="button"
              onClick={this.handleCloseButton}
            > <CloseModalIcon />
            </button>
            )}
            <h2 className={`modal__title ${mailSendResponse && 'email-send'}`}>
              {mailSendResponse ? 'Email Sent' : 'Send the configuration to your email'}
            </h2>
          </div>
          { mailSendResponse
            ? (
              <div className="modal__body email-send">
                <p className="email-text">{mailSendResponse}</p>
                <CustomButton
                  customClass="btn-email"
                  text="Close"
                  onButtonClick={this.handleCloseButton}
                />
              </div>
            )
            : (
              <div className="modal__body">
                <p className="email-text">Enter your email address</p>
                {validationError && <span className="modal__input-error"> {validationError} </span>}
                <input
                  name="email"
                  type="email"
                  className={`email-input ${emailValue && 'active'}`}
                  placeholder="email@example.com"
                  required
                  value={emailValue}
                  onChange={this.onInputChange}
                />
                <input
                  type="checkbox"
                  className="checkbox-news"
                  id="checkmark"
                  defaultChecked={checkboxValue}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="checkmark" className="checkbox-news" />
                <p className={`checkbox-text ${emailValue && 'active'}`}>
                  Send me updates from Include about new products or special promotions.
                </p>
                <div className="btn-email-wrap">
                  <CustomButton
                    customClass="btn-email"
                    text={loading ? 'Sending' : 'Send'}
                    disabled={!emailValue || loading}
                    onButtonClick={this.handleButtonClick}
                  />
                </div>
              </div>
            )}
        </Modal>
      </>
    );
  }
}

EmailModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  configID: PropTypes.string.isRequired,
  mailSendResponse: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  apiError: state.summary.error,
  configID: state.summary.selectedModelConfigId,
  mailSendResponse: state.summary.emailSendResponse,
  loading: state.summary.loading,
});

export default connect(mapStateToProps)(EmailModal);
