import React from 'react';
import Image404 from '../../../shared/img/404.jpg';

const NotFound404 = () => (
  <div className="">
    <div className="">
      <img className="" src={Image404} alt="404" />
      {/* <h3 className="not-found__info">Ooops! The page you are looking for could not be found :(</h3>
      <h3 className="not-found__info">Double check that URL! :)</h3> */}
    </div>
  </div>
);

export default NotFound404;
