import React from 'react';
import PropTypes from 'prop-types';

const LeftIcon = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0625 18.25C24.4767 18.25 24.8125 17.9142 24.8125 17.5C24.8125 17.0858 24.4767 16.75
   24.0625 16.75V18.25ZM10.4072 16.9697C10.1143 17.2626 10.1143 17.7374 10.4072 18.0303L15.1801 22.8033C15.473
   23.0962 15.9479 23.0962 16.2408 22.8033C16.5337 22.5104 16.5337 22.0355 16.2408 21.7426L11.9982 17.5L16.2408
   13.2574C16.5337 12.9645 16.5337 12.4896 16.2408 12.1967C15.9479 11.9038 15.473 11.9038 15.1801 12.1967L10.4072
   16.9697ZM24.0625 16.75H10.9375V18.25H24.0625V16.75Z"
      fill={color}
    />
  </svg>
);

LeftIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LeftIcon;
