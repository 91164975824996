import React from 'react';
import PropTypes from 'prop-types';

const ModelInfo = ({ name, slug }) => {
  // Checking for product type (benches or waste containers)
  // Checking if it is waste container to display correct picture
  let productType = '';
  let pictureOverview = slug;

  if (slug.includes('steora')) {
    productType = 'benches';
  } else if (slug.includes('terra')) {
    pictureOverview = `${slug}-overview`;
    productType = 'waste-containers';
  } else {
    productType = 'aerys';
  }
  return (
    <div className="product-description">
      <div className="description">{name}</div>
      <div className="picture">
        <img src={`${process.env.REACT_APP_UPLOADS_FOLDER}/${productType}/${pictureOverview}.png`} alt={slug} />
      </div>
    </div>

  );
};

ModelInfo.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ModelInfo;
