import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadSavedConfiguration from './components/LoadSavedConfiguration';
import EmailModal from './components/Email';
import TechSpecsModal from './components/TechSpecsModal';
import EquipmentModal from './components/EquipmentModal';
import RequiredModuleModal from './components/RequiredModuleModal';

class Modal extends PureComponent {
  render() {
    const {
      modalType, modalOpen, onCloseClick, techSpecs, standardEquipment, requiredModuleNames,
    } = this.props;

    return (
      <>
        {modalType === 'configuration' && (
        <LoadSavedConfiguration
          modalOpen={modalOpen}
          onCloseClick={onCloseClick}
        />
        )}
        {modalType === 'email' && (
          <EmailModal
            modalOpen={modalOpen}
            onCloseClick={onCloseClick}
          />
        )}
        {modalType === 'technical' && (
          <TechSpecsModal
            modalOpen={modalOpen}
            onCloseClick={onCloseClick}
            techSpecs={techSpecs}
          />
        )}
        {modalType === 'equipment' && (
        <EquipmentModal
          modalOpen={modalOpen}
          onCloseClick={onCloseClick}
          standardEquipment={standardEquipment}
        />
        )}
        {modalType === 'required-modul' && (
        <RequiredModuleModal
          modalOpen={modalOpen}
          onCloseClick={onCloseClick}
          requiredModuleNames={requiredModuleNames}
        />
        )}

      </>
    );
  }
}

Modal.propTypes = {
  modalType: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  techSpecs: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])),
  standardEquipment: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])),
  requiredModuleNames: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.array,
  ])),
};

Modal.defaultProps = {
  techSpecs: [],
  standardEquipment: {},
  requiredModuleNames: {
    name: '',
    requires: [],
  },
};

export default (Modal);
