import React from 'react';
import PropTypes from 'prop-types';
import OrderingMenu from '../../../components/OverviewMenu/OrderingMenu';
import Connection from '../../../components/Connection';

const ConnectionsConfigurator = ({
  modelInfo, handleCreateOrderButton, handleConnectionSelect, handleBackButton, connectionTypeSelected,
}) => (
  <>
    <OrderingMenu
      handleNextButton={(event) => handleCreateOrderButton(event)}
      handleBackButton={(event) => handleBackButton(event, 'equipment')}
    />
    <h1>Choose your connection</h1>
    <h3 className="sub-title">Because you have chosen a Wi-Fi module or LCD display on the previous page,
      these additional options can be connected on the internet via SIM card or Ethernet cable.
    </h3>
    {modelInfo.modules && modelInfo.modules.length > 0 && (
      <Connection
        connectionList={modelInfo.modules}
        onConnectionSelect={handleConnectionSelect}
        connectionTypeSelected={connectionTypeSelected}
      />
    )}
  </>
);

ConnectionsConfigurator.propTypes = {
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  connectionTypeSelected: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  handleCreateOrderButton: PropTypes.func.isRequired,
  handleConnectionSelect: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
};

export default ConnectionsConfigurator;
