import React from 'react';

const CheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="white" />
    <path
      d="M10.308 13.8952L7.704 11.2912L6 12.9832L10.308 17.2912L18.708 8.89122L17.016 7.19922L10.308
    13.8952Z"
      fill="black"
    />
  </svg>
);

export default CheckIcon;
