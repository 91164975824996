import common_en from './en/common_en.json';
import common_hr from './hr/common_hr.json';

export default {
  en: {
    common: common_en,
  },
  hr: {
    common: common_hr,
  },
};
