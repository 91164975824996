// PRICES - code commented for NOW
import React from 'react';
import PropTypes from 'prop-types';
import AccessoriesInfo from './components/AccessoriesInfo';
import PriceInfo from './components/PriceInfo';
import CustomButton from '../Button';
import ConfigurationID from './components/ConfigurationId';

const SummaryMenu = ({
  data,
  quantity,
  configID,
  dispatch,
  modelInfo,
}) => {
  const summary = {
    color: data.color,
    modules: data.selected_modules,
    accessories: data.selected_accessories,
  };

  // let pricePerUnit = data.price;
  // const colorPrice = data.color.price;
  // let accessoriesPrice = 0;
  // let modulesPrice = 0;

  // if (data.selected_accessories.length > 0) {
  //   data.selected_accessories.forEach((accessory) => {
  //     accessoriesPrice += accessory.price;
  //   });
  // }
  // if (data.selected_modules.length > 0) {
  //   data.selected_modules.forEach((module) => {
  //     modulesPrice += module.price;
  //   });
  // }

  // pricePerUnit += colorPrice + accessoriesPrice + modulesPrice;
  // pricePerUnit /= 100;
  // pricePerUnit = pricePerUnit.toFixed(2);

  return (
    <div className="overview-menu-container">
      <div className="title">
        <h2>Overview</h2>
      </div>
      <ConfigurationID configID={configID} dispatch={dispatch} />
      <AccessoriesInfo
        basicPrice={data.price_formatted}
        data={summary}
        modelInfo={modelInfo}
        currentPage="summary"
      />
      <PriceInfo
        disableTotalPriceUpdate
        // pricePerUnit={pricePerUnit}
        quantity={quantity}
      />
      <div className="overview-menu__button-wrapper">
        <CustomButton padding="10px" text="Create new configuration" customClass="new-configuration" />
      </div>
    </div>
  );
};

SummaryMenu.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
  quantity: PropTypes.number.isRequired,
  configID: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array,
  ])).isRequired,
};

export default SummaryMenu;
