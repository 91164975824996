import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../Button';

const MehanicalAccessories = ({
  accessoriesList, onAccessoriesClick, selectedAccessories, overviewData, modelInfo,
}) => {
  const substringRalFromColorName = (name) => {
    // Remove "RAL " chars from color name
    const shortenName = name.substring(4);
    return shortenName;
  };
  const colorFolderName = overviewData.color && overviewData.color.name
    ? substringRalFromColorName(overviewData.color.name) : '9001';
  return (
    <div className="accessories-picker">
      {modelInfo.slug.includes('steora')
      || modelInfo.slug.includes('aerys') ? <h2>Mechanical accessories:</h2> : <h2>Waste lid signage:</h2>}
      {/* <ul className="mechanical-accesories-category-list">

    //// * OVAJ BLOCK CODE-a ODKOMENTIRATI KADA DOBIJEMO LISTU ACCESSORIES-a PO KATEGORIJAMA * ////

      {
        accessoriesList.map((item) => (
          <li key={item.id}>
            <span className="mechanical-accesories-category-item">
              {item.name}
            </span>
          </li>
        ))
      }
    </ul> */}
      <div className="mechanical-accesories">
        {accessoriesList.map((item) => (
          !item.standard_equipment
        && (
        <div className="accessory-item" key={item.id}>
          {/* tooltip-ime dodatka
          <UncontrolledTooltip placement="top" target={`TooltipTop${item.id}`}>{item.name} </UncontrolledTooltip>
          */}
          <img
            src={`${process.env.REACT_APP_UPLOADS_ICONS_FOLDER}accessories/${colorFolderName}/${item.image}`}
            alt={item.image}
            id={`TooltipTop${item.id}`}
          />
          {
              modelInfo.slug.includes('steora')
                ? (
                  <CustomButton
                    text={selectedAccessories.includes(item.id) ? 'Remove' : 'Add'}
                    icon="add"
                    padding="3px 14px"
                    customClass={`${selectedAccessories.includes(item.id) ? 'button-active' : ''}`}
                    onButtonClick={(event) => onAccessoriesClick(event, item)}
                  />
                )
                : (
                  <CustomButton
                    text={selectedAccessories.includes(item.id) ? 'Added' : 'Add'}
                    icon="add"
                    padding="3px 14px"
                    customClass={`${selectedAccessories.includes(item.id) ? 'button-active' : ''}`}
                    onButtonClick={(event) => onAccessoriesClick(event, item)}
                    disabled={(selectedAccessories.includes(item.id))}
                  />
                )
          }
        </div>
        )
        ))}
      </div>
    </div>
  );
};

MehanicalAccessories.propTypes = {
  accessoriesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAccessoriesClick: PropTypes.func.isRequired,
  selectedAccessories: PropTypes.arrayOf(PropTypes.number).isRequired,
  overviewData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  modelInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
};

export default MehanicalAccessories;
